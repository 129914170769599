import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl, HttpContentType } from '../../service/AxiosSetup';

export interface State {
  status: string;
  data: any;
}

const initialState: State = {
  status: 'idle',
  data: null,
};

export interface FileUploadPayload {
  File: any;
  Path: string;
  Name: string;
}
export interface FileDeletePayload {
  path: string;
}

export const postFileUpload = createAsyncThunk(
  'fileUpload',
  async (data: FileUploadPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(
        getUrl('/upload', {}),
        data,
        getConfig(true, HttpContentType.MultipartFormData),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const deleteFile = createAsyncThunk(
  'fileDelete',
  async (data: FileDeletePayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.delete(
        getUrl('/upload/remove', { query: { path: data.path } }),
        getConfig(true, HttpContentType.MultipartFormData),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postFileUpload.pending, (state, action) => {
        const x: any = action.meta.arg;
        // eslint-disable-next-line
        for (const pair of x.entries()) {
          if (pair[0] === 'Name') {
            state.status = `loading-${pair[1]}`;
          }
        }
      })
      .addCase(postFileUpload.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postFileUpload.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      })
      .addCase(deleteFile.pending, (state, action) => {
        const { path } = action.meta.arg;
        state.status = `deleting-${path}`;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.fileUpload.status;
export const selectData = (state: RootState) => state.fileUpload.data;

export default fileUploadSlice.reducer;
