import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../../service/AxiosSetup';

export interface SupplierState {
  status: 'idle' | 'loading-suppliers' | 'failed' | 'success' | 'loading-items';
  supplierData: any;
  supplierItemData: any;
}

const initialState: SupplierState = {
  status: 'idle',
  supplierData: null,
  supplierItemData: null,
};

export interface SupplierItemPayload {
  supplierId: string | null | undefined;
}

export const postGetSuppliers = createAsyncThunk(
  'getSuppliers',
  async (data: {}, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('supplier/find', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);
export const postGetSupplierItems = createAsyncThunk(
  'getSupplierItems',
  async (data: SupplierItemPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('supplier-item/get', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const supplierSlice = createSlice({
  name: 'newApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postGetSuppliers.pending, (state) => {
        state.status = 'loading-suppliers';
      })
      .addCase(postGetSuppliers.fulfilled, (state, action) => {
        state.status = 'success';
        state.supplierData = action.payload;
      })
      .addCase(postGetSuppliers.rejected, (state, action) => {
        state.status = 'failed';
        state.supplierData = action.payload;
      });
    builder
      .addCase(postGetSupplierItems.pending, (state) => {
        state.status = 'loading-items';
      })
      .addCase(postGetSupplierItems.fulfilled, (state, action) => {
        state.status = 'success';
        state.supplierItemData = action.payload;
      })
      .addCase(postGetSupplierItems.rejected, (state, action) => {
        state.status = 'failed';
        state.supplierItemData = action.payload;
      });
  },
});

export const selectSupplierLoading = (state: RootState) => state.supplier.status;
export const selectSupplierData = (state: RootState) => state.supplier.supplierData;
export const selectSupplierItemData = (state: RootState) => state.supplier.supplierItemData;

export default supplierSlice.reducer;
