import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../theme/Theme';

interface Props {
  text: string;
  error?: boolean;
  style?: any;
  alwaysShow?: boolean;
  size?: number;
  sx?: any;
}

const Label: FC<Props> = ({ text, error, style, alwaysShow, size, sx }) => {
  return (
    <Box style={style} display="flex" p="2px" alignItems="center">
      {(error || alwaysShow) && (
        <Typography
          sx={sx}
          style={{ lineHeight: 1, textAlign: 'left', fontSize: size ? size : 12 }}
          color={error ? colors.errorText : ''}
          variant="caption"
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Label;
