import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../AxiosSetup';
import { BeneficiaryObj } from './ApplicationModels';

export interface BeneficiaryState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: BeneficiaryState = {
  status: 'idle',
  data: null,
};

export const postBeneficiary = createAsyncThunk(
  'Beneficiary',
  async (data: BeneficiaryObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(
        getUrl('application/save/beneficiary-details', {}),
        data,
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const BeneficiarySlice = createSlice({
  name: 'Beneficiary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postBeneficiary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postBeneficiary.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postBeneficiary.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectSaveBeneficiaryLoading = (state: RootState) => state.saveBeneficiary.status;
export const selectBeneficiaryData = (state: RootState) => state.saveBeneficiary.data;

export default BeneficiarySlice.reducer;
