import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ChecklistObj, Status } from '../../service/application/ApplicationModels';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PendingIcon from '@mui/icons-material/PendingOutlined';
import {
  putDeliveryChecklist,
  selectGetDeliveryChecklistLoading,
  selectPutDeliveryChecklistLoading,
} from '../../service/application/DeliveryChecklist';
import { LoadingButton } from '@mui/lab';
import { hasAdminPermission } from '../../helper/AppHelper';
import { postNewApplication } from '../../service/application/NewApplicationSlice';

interface Props {
  applicationId: string;
  checklist: any;
  fetchChecklist: Function;
  getData: () => void;
  status: string;
}

const Checklist: FC<Props> = ({ applicationId, checklist, fetchChecklist, getData, status }) => {
  const [activeChecklist, setActiveChecklist] = useState<string | undefined>(undefined);

  const dispatch = useAppDispatch();

  const putChecklist = async (data: ChecklistObj) => {
    const response = await dispatch(putDeliveryChecklist(data));
    if (response.type.includes('fulfilled')) {
      fetchChecklist();
      setActiveChecklist(undefined);
    }
  };

  const getLoading = useAppSelector(selectGetDeliveryChecklistLoading);
  const putLoading = useAppSelector(selectPutDeliveryChecklistLoading);

  const handleChange = (checked: boolean, checklistId: string, itemId: string) => {
    setActiveChecklist(itemId);
    const data = {
      applicationId,
      checklistId,
      itemId,
      isComplete: checked,
    };
    putChecklist(data);
  };

  const handleBtn = async () => {
    const response = await dispatch(
      postNewApplication({
        applicationId,
        applicationStatus: 'Complete',
      }),
    );
    if (response.type.includes('fulfilled')) {
      getData();
    }
  };

  return (
    <Box textAlign="left" sx={{ pl: { xs: 1, sm: 2 }, pr: { xs: 1, sm: 2 } }}>
      {checklist &&
        checklist?.checklists.map((c: any) => (
          <Box
            sx={{
              mb: 2,
              bgcolor: 'white',
              width: { xs: '100%', sm: '90%' },
              maxWidth: 600,
              p: { xs: 1, sm: 2 },
              pl: { xs: 2, sm: 3 },
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontFamily: 'Roboto',
                fontSize: 16,
              }}
            >
              {c.title}
              {c.isComplete ? (
                <CheckCircleIcon sx={{ fontSize: 20, ml: 1, mb: -0.5 }} color="success" />
              ) : (
                <PendingIcon sx={{ fontSize: 20, ml: 1, mb: -0.5 }} color="warning" />
              )}
            </Typography>
            {c.items.map((i: any) => (
              <Box display="flex">
                <FormControlLabel
                  sx={{ alignItems: 'inline-block', position: 'relative', width: '100%' }}
                  control={
                    <Checkbox
                      checked={i.isComplete}
                      disabled={!hasAdminPermission() || status === Status.Complete}
                      onChange={(e) => {
                        handleChange(e.target.checked, c.id, i.id);
                      }}
                    />
                  }
                  label={
                    <Box fontSize={14}>
                      {i.description}
                      {(putLoading === 'loading' || getLoading === 'loading') &&
                        activeChecklist === i.id && (
                          <CircularProgress sx={{ mb: '-2px', ml: 1.5 }} size="15px" />
                        )}
                    </Box>
                  }
                />
                <br />
              </Box>
            ))}
          </Box>
        ))}
      {checklist?.isComplete && status === Status.Approved && (
        <LoadingButton
          loading={false}
          sx={{ width: 200 }}
          variant="contained"
          onClick={() => handleBtn()}
          autoFocus
        >
          MARK AS DELIVERED
        </LoadingButton>
      )}
    </Box>
  );
};

export default Checklist;
