import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getUrl } from '../../service/AxiosSetup';

export interface ForgotPasswordState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: ForgotPasswordState = {
  status: 'idle',
  data: null,
};

export interface ForgotPasswordPayload {
  email: string | null | undefined;
}

export const postForgotPassword = createAsyncThunk(
  'forgotPassword',
  async (data: ForgotPasswordPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('auth/forgot-password', {}), data);
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postForgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postForgotPassword.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postForgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.forgotPassword.status;
export const selectData = (state: RootState) => state.forgotPassword.data;

export default forgotPasswordSlice.reducer;
