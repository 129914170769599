import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../theme/Theme';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#007DDC',
      },
    },
    '&.Mui-disabled': {
      opacity: 0.6,
      '& + .MuiSwitch-track': {
        cursor: 'not-allowed',
        opacity: 0.4,
        backgroundColor: '#007DDC',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 12,
    backgroundColor: '#ffffff',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: colors.border,
    boxSizing: 'border-box',
  },
}));

export default CustomSwitch;
