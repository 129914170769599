import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getUrl } from '../../service/AxiosSetup';

export interface RegisterProfessionalState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: RegisterProfessionalState = {
  status: 'idle',
  data: null,
};

export interface RegisterProfessionalPayload {
  email: string | null | undefined;
  password?: string | null | undefined;
  socials: Socials;
  contactInfo: ContactInfo;
  workplaceInfo: WorkplaceInfo;
  name: Name;
  phoneNumber: string | null | undefined;
  role: string | null | undefined;
}

export interface ContactInfo {
  phone: string | null | undefined;
  email: string | null | undefined;
}

export interface Name {
  preferredName?: string | null | undefined;
  title: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
}

export interface Socials {
  linkedIn: string | null | undefined;
  instagram: string | null | undefined;
}

export interface WorkplaceInfo {
  role: string | null | undefined;
  organisation: string | null | undefined;
  ahpra?: string | null | undefined;
  department?: string | null | undefined;
}

export const postRegisterProfessional = createAsyncThunk(
  'registerProfessional',
  async (data: RegisterProfessionalPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const url = data.password ? 'auth/signup/native' : 'auth/signup/external-provider';
      const response = await axios.post(getUrl(url, {}), data);
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const registerProfessionalSlice = createSlice({
  name: 'registerProfessional',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postRegisterProfessional.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postRegisterProfessional.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload as string;
      })
      .addCase(postRegisterProfessional.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.registerProfessional.status;
export const selectData = (state: RootState) => state.registerProfessional.data;

export default registerProfessionalSlice.reducer;
