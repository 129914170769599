import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../../service/AxiosSetup';

export interface LoginState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: LoginState = {
  status: 'idle',
  data: null,
};

export interface LoginPayload {
  email: string | null | undefined;
  password: string | null | undefined;
}

export interface GoogleLoginPayload {
  code: string | null | undefined;
}

export const getGoogleSigninUrl = createAsyncThunk(
  'get-google-signin',
  async (__, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.get(getUrl('auth/get-google-signin-url', {}));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const postGoogleCode = createAsyncThunk(
  'post-google-code',
  async (data: GoogleLoginPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('auth/signin-with-code', {}), data);
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const postLogin = createAsyncThunk(
  'login',
  async (data: LoginPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('auth/signin', {}), data);
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const postLogout = createAsyncThunk('logout', async (__, { rejectWithValue }) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios.post(getUrl('auth/sign-out', {}), {}, getConfig(true));
    return response.data;
  } catch (e: any) {
    if (!e.response) {
      throw e;
    }
    return rejectWithValue(e.response.data);
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
    builder
      .addCase(getGoogleSigninUrl.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getGoogleSigninUrl.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
    builder
      .addCase(postGoogleCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postGoogleCode.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postGoogleCode.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
    builder
      .addCase(postLogout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postLogout.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postLogout.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.login.status;
export const selectData = (state: RootState) => state.login.data;

export default loginSlice.reducer;
