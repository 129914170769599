import {
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
} from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import logo from '../../assets/images/mad_logo.png';
import PractitionerLogo from '../../assets/images/practitioner.png';
import SupplierLogo from '../../assets/images/supplier.png';
import { useStyles } from './RegisterStyles';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import Label from '../../component/Label';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ValidateEmail,
  ValidatePassword,
  ValidatePhone,
  CapitalizeFirstLetter,
} from '../../helper/AppHelper';
import { getTitle, selectLoading } from '../../service/register/RegisterSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import createDecorator from 'final-form-focus';
import { colors } from '../../theme/Theme';

export interface RegisterForm {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  type: string | null | undefined;
  phone: string | null | undefined;
  email?: string | null | undefined;
  password?: string | null | undefined;
  confirmPassword?: string | null | undefined;
  title: string | null | undefined;
  newUser?: boolean | null | undefined;
}

const focusOnErrors: any = createDecorator();

const Register: FC<{}> = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [titles, setTitles] = useState<string[] | undefined>(undefined);
  const titleLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const onSubmit = (values: RegisterForm) => {
    navigate(
      `${
        values.type === 'healthcareProfessional'
          ? '/register-healthcare-professional'
          : '/register-supplier'
      }`,
      {
        state: {
          ...values,
          ...(newUser && { email: (location.state as { email: string }).email, newUser }),
        },
      },
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getTitle());
      if (response.type.includes('fulfilled')) {
        setTitles(response.payload);
      }
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newUser = location.state ? (location.state as { newUser: boolean }).newUser : null;
    if (newUser) {
      setNewUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (values: RegisterForm) => {
    const { firstName, lastName, type, phone, email, password, confirmPassword, title } = values;
    const errors: RegisterForm = {
      title: title ? undefined : 'Select title',
      firstName: firstName ? undefined : 'Please enter your first name',
      lastName: lastName ? undefined : 'Please enter your last name',
      type: type ? undefined : 'Please select your account type',
      phone: ValidatePhone(phone) ? undefined : 'Please enter a valid phone no',
      ...(!newUser && {
        email: ValidateEmail(email) ? undefined : 'Please enter a valid email address',
        password: ValidatePassword(password),
        confirmPassword:
          confirmPassword && password === confirmPassword ? undefined : 'Passwords don’t match',
      }),
    };
    return errors;
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    // eslint-disable-next-line
    event.preventDefault();
  };

  const getInitialData = () => {
    const firstName = location.state ? (location.state as { firstName: string }).firstName : null;
    const lastName = location.state ? (location.state as { lastName: string }).lastName : null;
    const data = {
      firstName: CapitalizeFirstLetter(firstName),
      lastName: CapitalizeFirstLetter(lastName),
    };
    return data;
  };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      sx={{ justifyContent: 'center' }}
      borderLeft={`4px solid ${colors.pageBorder}`}
    >
      <img
        title="Go to Login"
        onClick={() => navigate('/login')}
        width={100}
        height={44}
        src={logo}
        style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
      />
      <Grid sx={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid item xs={11} sm={6} md={4} lg={3}>
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: { xs: '100%', sm: 420 },
              px: { xs: 1, sm: 5 },
              py: 4,
              mt: { xs: 10, sm: 0 },
            }}
          >
            <Typography className={styles.register} variant="h3">
              Create a new account
            </Typography>
            <Typography className={styles.register} variant="h6">
              {/* Lorem ipsum dolor sit amet, consectetur. */}
            </Typography>
            <Form
              onSubmit={onSubmit}
              decorators={[focusOnErrors]}
              validate={validate}
              initialValues={getInitialData()}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="title"
                    render={({ input, meta }) => (
                      <Box height={72} mt={2}>
                        <FormControl fullWidth>
                          <InputLabel {...{ size: 'small' }} error={meta.touched && meta.error}>
                            Title
                          </InputLabel>
                          <Select
                            fullWidth
                            inputProps={input}
                            value={input.value}
                            label="Title"
                            className={styles.select}
                            onChange={input.onChange}
                            error={meta.error && meta.touched}
                            endAdornment={
                              <Box>
                                {titleLoading === 'loading' && (
                                  <CircularProgress
                                    size="20px"
                                    style={{ marginRight: 20, marginTop: 5 }}
                                  />
                                )}
                              </Box>
                            }
                          >
                            {titles && titles.length ? (
                              titles.map((s: string) => (
                                <MenuItem key={s} value={s}>
                                  {s}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem key={'NA'} value={undefined}>
                                No titles available
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        <Label
                          style={{ paddingLeft: 14, paddingTop: 4 }}
                          error={meta.touched && meta.error}
                          text={meta.error}
                        />
                      </Box>
                    )}
                  />
                  <Field
                    name="firstName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="First name" />
                    )}
                  />
                  <Field
                    name="lastName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Last name" />
                    )}
                  />
                  <Field
                    name="type"
                    render={({ input, meta }) => (
                      <Box height={72}>
                        <FormControl fullWidth>
                          <InputLabel {...{ size: 'small' }} error={meta.touched && meta.error}>
                            Account Type
                          </InputLabel>
                          <Select
                            fullWidth
                            value={input.value}
                            label="Account Type"
                            className={styles.select}
                            onChange={input.onChange}
                            error={meta.error && meta.touched}
                          >
                            <MenuItem
                              style={{ alignItems: 'center' }}
                              value={'healthcareProfessional'}
                            >
                              <img className={styles.logo} src={PractitionerLogo} />
                              Healthcare professional
                            </MenuItem>
                            <MenuItem
                              disabled
                              style={{ alignItems: 'center' }}
                              value={'equipmentSupplier'}
                            >
                              <img className={styles.logo} src={SupplierLogo} />
                              Equipment supplier
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Label error={meta.touched && meta.error} text={meta.error} />
                      </Box>
                    )}
                  />
                  <Field
                    name="phone"
                    render={({ input, meta }) => (
                      <CustomInput
                        input={input}
                        meta={meta}
                        label="Phone number"
                        startAdornment="phone"
                      />
                    )}
                  />
                  {!newUser && (
                    <>
                      <Field
                        name="email"
                        render={({ input, meta }) => (
                          <CustomInput input={input} meta={meta} label="Email" />
                        )}
                      />
                      <Field
                        name="password"
                        render={({ input, meta }) => (
                          <Box height={72}>
                            <TextField
                              {...input}
                              label="Password"
                              error={meta.touched && meta.error}
                              InputProps={{
                                name: 'password',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              type={showPassword ? 'text' : 'password'}
                              size="small"
                              fullWidth
                            />
                            <Label
                              style={{ paddingLeft: 14, paddingTop: 4 }}
                              error={meta.touched && meta.error}
                              text={meta.error}
                            />
                          </Box>
                        )}
                      />
                      <Box
                        textAlign="left"
                        color={colors.activeFilter}
                        fontSize={12}
                        display="flex"
                        mt={-2.5}
                        height={80}
                      >
                        <ul>
                          <li>One lowercase character</li>
                          <li>One uppercase character</li>
                          <li>One number</li>
                        </ul>
                        <ul>
                          <li>One special character</li>
                          <li>8 charactres minimum</li>
                        </ul>
                      </Box>
                      <Field
                        name="confirmPassword"
                        render={({ input, meta }) => (
                          <Box height={68}>
                            <TextField
                              {...input}
                              label="Confirm Password"
                              error={meta.touched && meta.error}
                              InputProps={{
                                name: 'confirmPassword',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              type={showConfirmPassword ? 'text' : 'password'}
                              size="small"
                              fullWidth
                            />
                            <Label
                              style={{ paddingLeft: 14, paddingTop: 4 }}
                              error={meta.touched && meta.error}
                              text={meta.error}
                            />
                          </Box>
                        )}
                      />
                    </>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    className={styles.submitBtn}
                    fullWidth
                    color="primary"
                  >
                    Next
                  </Button>
                </form>
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Register;
