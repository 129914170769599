import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import MobileGrid from '../../component/MobileGrid/MobileGrid';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridRowModel,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  dollarFormatter,
  getStatusColor,
  hasAdminPermission,
  hasPractitionerPermission,
} from '../../helper/AppHelper';
import moment from 'moment';
import Chip from '../../component/Chip';
import {
  getRecentApplications,
  postGetAllApplications,
  selectGetAllApplicationsLoading,
} from '../../service/application/ApplicationListSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { colors } from '../../theme/Theme';

const useStyles = makeStyles({
  header: {
    padding: `18px !important`,
    background: '#e0e0e0',
    border: 'none',
    color: '#007DDC',
  },
});

const Home: FC<{}> = ({}) => {
  const theme = useTheme();
  const styles = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [recentApplications, setRecentApplications] = useState<number>(0);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectGetAllApplicationsLoading);

  const handleOpen = (data: GridRowModel) => {
    if (data.applicationStatus === 'Draft') {
      navigate('/new-application/supplier', { state: { applicationId: data.applicationId } });
    } else {
      navigate(`/new-application/summary/${data.applicationId}`, {
        state: { applicationId: data.applicationId },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const api = postGetAllApplications;
      const response = await dispatch(api({ paginationOption: { size: 5 } }));
      if (response.type.includes('fulfilled')) {
        setFilteredApplications(response.payload.results);
      }
    };
    fetchData().catch(console.error);

    if (hasAdminPermission()) {
      const fetchRecentApplications = async () => {
        const api = getRecentApplications;
        const response = await dispatch(api({}));
        if (response.type.includes('fulfilled')) {
          setRecentApplications(response.payload.totalItems);
        }
      };
      fetchRecentApplications().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = useMemo(
    () =>
      (filteredApplications || []).map((row: any, i: number) => {
        return { ...row, id: i };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [filteredApplications],
  );
  const columns: GridColDef[] = [
    {
      field: 'dateLodged',
      headerName: 'Date',
      width: 115,
      sortable: false,
      align: 'left',
      headerClassName: styles.header,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.dateLodged || params.row.dateCreated}`,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {moment(params.value).format('DD MMM YYYY')}
        </Box>
      ),
    },
    {
      field: 'applicationId',
      headerName: 'Lodgement #',
      sortable: false,
      width: 150,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'applicant',
      headerName: 'Applicant',
      sortable: false,
      width: 210,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => `${params.row.applicantName.fullName || ''}`,
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'cost',
      headerName: 'Cost',
      sortable: false,
      width: 110,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {dollarFormatter(params.value)}
        </Box>
      ),
    },
    {
      field: 'beneficiary',
      headerName: 'Beneficiary',
      sortable: false,
      width: 210,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.beneficiaryName.fullName || ''}`,
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'applicationStatus',
      headerName: 'Status',
      sortable: false,
      description: 'This column has a value getter and is not sortable.',
      width: 183,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box
          sx={{ cursor: 'pointer' }}
          width={180}
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Chip showIcon color={getStatusColor(params.value!)} text={params.value!} />
          </Box>
        </Box>
      ),
    },
  ];
  return (
    <Box display="flex" height="95%" minHeight={`calc(100vh - 65px)`}>
      <Box display="flex" height="100%" width="100%">
        <Box
          sx={{ pl: { xs: 1.5, sm: 12 }, pb: 6, pr: { xs: 2, sm: 0 }, pt: 4 }}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Grid direction="column" sx={{ flexWrap: 'nowrap' }} container>
            <Grid sx={{ maxWidth: '996px !important', width: '100%', px: 0 }} item>
              <Box textAlign="left">
                <Typography
                  color="primary"
                  sx={{ fontSize: 16, color: '#000', fontWeight: 'bold', mb: 1 }}
                >
                  Quick links
                </Typography>
                <Box
                  display="flex"
                  sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
                  flexWrap="wrap"
                  mb={2}
                >
                  {hasAdminPermission() && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      sx={{
                        bgcolor: '#fff',
                        height: 180,
                        width: 180,
                        p: 2,
                        textAlign: 'left',
                        mr: 2,
                        mb: 1,
                        borderRadius: 1,
                        border: '1px solid #eee',
                      }}
                    >
                      <Typography color="primary" sx={{ fontSize: 14, color: '#000' }}>
                        New applications today
                      </Typography>
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: 40,
                          color: colors.grey600,
                          fontWeight: 500,
                          lineHeight: 1,
                          textAlign: 'center',
                        }}
                      >
                        {recentApplications}
                      </Typography>
                      <Button onClick={() => navigate('/my-applications')} variant="outlined">
                        VIEW
                      </Button>
                    </Box>
                  )}
                  {(hasAdminPermission() || hasPractitionerPermission()) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      sx={{
                        bgcolor: '#fff',
                        height: 180,
                        width: 180,
                        p: 2,
                        textAlign: 'left',
                        mr: 2,
                        mb: 1,
                        borderRadius: 1,
                        border: '1px solid #eee',
                      }}
                    >
                      <Typography color="primary" sx={{ fontSize: 14, color: '#000', mb: 1 }}>
                        Start a new application for a beneficiary here
                      </Typography>
                      <Button
                        onClick={() => navigate('/new-application/supplier')}
                        variant="contained"
                      >
                        GET STARTED
                      </Button>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      bgcolor: '#fff',
                      height: 180,
                      width: 180,
                      p: 2,
                      mr: 2,
                      mb: 1,
                      textAlign: 'left',
                      border: '1px solid #eee',
                      borderRadius: 1,
                    }}
                  >
                    <Typography color="primary" sx={{ fontSize: 14, color: '#000', mb: 1 }}>
                      How to get an application strated. What happens?
                    </Typography>
                    <Button variant="outlined">FIND OUT MORE</Button>
                  </Box>
                </Box>
              </Box>
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Typography
                    color="primary"
                    sx={{ fontSize: 16, color: '#000', fontWeight: 'bold', mb: 1 }}
                  >
                    Applications
                  </Typography>
                  <Typography
                    onClick={() => navigate('/my-applications')}
                    color="primary"
                    sx={{
                      fontSize: 16,
                      color: colors.grey700,
                      textDecoration: 'underline',
                      mb: 1,
                      mr: 2,
                      cursor: 'pointer',
                    }}
                  >
                    View All
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} sx={{ maxWidth: `980px !important` }}>
              <Box>
                {isMobile ? (
                  <MobileGrid hidePagination rows={rows} />
                ) : (
                  <DataGrid
                    rows={rows}
                    sx={{
                      '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: '600',
                      },
                    }}
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    rowsPerPageOptions={[]}
                    columns={columns}
                    loading={loading === 'loading'}
                    hideFooter
                    onRowClick={(params) => handleOpen(params.row)}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
