import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { FC } from 'react';
import { VerticalTabPanel } from '../../component/TabPanel';
import { colors } from '../../theme/Theme';
import Image1 from '../../assets/images/image-1.png';
import Image2 from '../../assets/images/image-2.png';
import Image3 from '../../assets/images/image-3.png';
import Image4 from '../../assets/images/image-4.png';
import Image5 from '../../assets/images/image-5.png';
import Image6 from '../../assets/images/image-6.png';
import Image7 from '../../assets/images/image-7.png';
import Image8 from '../../assets/images/image-8.png';
import Image9 from '../../assets/images/image-9.png';
import Image10 from '../../assets/images/image-10.png';
import Image11 from '../../assets/images/image-11.png';
import Image12 from '../../assets/images/image-12.png';
import Image13 from '../../assets/images/image-13.png';
import Image21 from '../../assets/images/image-21.png';
import Image22 from '../../assets/images/image-22.png';
import Image23 from '../../assets/images/image-23.png';
import Image24 from '../../assets/images/image-24.png';
import Image25 from '../../assets/images/image-25.png';
import Chip from '../../component/Chip';
import { getStatusColor } from '../../helper/AppHelper';

const Help: FC<{}> = ({}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box display="flex">
      <Box
        sx={{ pl: { xs: 1.5, sm: 12 }, pb: 6, pr: { xs: 2, sm: 0 } }}
        display="flex"
        width="100%"
      >
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: colors.background, mt: 4, overflow: 'unset' }}
        >
          <Tab sx={{ width: 230, alignItems: 'flex-start' }} label="OVERVIEW" />
          <Tab sx={{ width: 230, alignItems: 'flex-start' }} label="APPLICATION PROCESS" />
          <Tab sx={{ width: 230, alignItems: 'flex-start' }} label="TRACK APPLICATION" />
        </Tabs>
        <Box pl={2}>
          <VerticalTabPanel value={value} index={0}>
            <Typography color={colors.primary} variant="h2">
              Overview
            </Typography>
            <Typography color={colors.grey600} fontWeight={400} sx={{ mt: 1.5 }} variant="h6">
              We have made it simple to apply and receive funding for the equipment and services
              requested by healthcare professionals on behalf of their clients. The new online
              application and approval process will see beneficiaries receive equipment and care
              faster than before whilst making it a stress free and standardised process for the
              applicants.
            </Typography>
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2 }} variant="h6">
              5 easy steps
            </Typography>
            <Typography color={colors.grey600} sx={{ mt: 2 }} fontWeight={400} variant="h6">
              <strong>1.</strong> Fill the online application form.
            </Typography>
            <Typography color={colors.grey600} sx={{ mt: 1.5 }} fontWeight={400} variant="h6">
              <strong>2.</strong> Upload all related documents to the MAD platform.
            </Typography>
            <Typography color={colors.grey600} sx={{ mt: 1.5 }} fontWeight={400} variant="h6">
              <strong>3.</strong> Submit for approval.
            </Typography>
            <Typography color={colors.grey600} sx={{ mt: 1.5 }} fontWeight={400} variant="h6">
              <strong>4.</strong> Application gets approved by MAD.
            </Typography>
            <Typography color={colors.grey600} sx={{ mt: 1.5 }} fontWeight={400} variant="h6">
              <strong>5.</strong> Funds are released to equipment supplier/s.
            </Typography>
          </VerticalTabPanel>
          <VerticalTabPanel value={value} index={1}>
            <Typography color={colors.primary} variant="h2">
              Application process
            </Typography>
            <Typography color={colors.grey600} fontWeight={400} sx={{ mt: 1.5 }} variant="h6">
              We have made it simple to apply and receive funding for the equipment and services
              requested by healthcare professionals on behalf of their clients. The new online
              application and approval process will see beneficiaries receive equipment and care
              faster than before whilst making it a stress free and standardised process for the
              applicants.
            </Typography>
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              1.Click on the applications tab
            </Typography>
            <img width="100%" src={Image1} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              2.Select NEW APPLICATION
            </Typography>
            <img width="100%" src={Image2} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2 }} variant="h6">
              3. Supplier and equipment details
            </Typography>
            <Typography
              color={colors.grey600}
              sx={{ mt: 0.5, mb: 1 }}
              fontWeight={400}
              variant="h6"
            >
              Make sure you have received a supplier quote before you start application process
            </Typography>
            <img width="100%" src={Image3} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3.1 Select supplier and equipment from our pre approved list.
            </Typography>
            <img width="100%" src={Image4} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3.2 Add additional items if from the same supplier and for the same beneficiary if
              required.
            </Typography>
            <img width="100%" src={Image5} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3.3 Enter the cost of the equipment as detailed in the supplier quote.
            </Typography>
            <img width="100%" src={Image6} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3.4 Select Estimated cost if the supplier quote isn’t finalised, and enter a cost
              range for MAD admins to review and approve.
            </Typography>
            <img width="100%" src={Image7} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3.5 Upload the supplier quote from the equipment supplier. MAX file size 4mb.
            </Typography>
            <img width="100%" src={Image8} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3.6 Add additional suppliers and equipment if applying for the same beneficiary
            </Typography>
            <img width="100%" src={Image9} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              4. Enter beneficiary and parents or carer details.
            </Typography>
            <img width="100%" src={Image10} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              4.1 Upload all documents regarding family circumstances, medical conditions, financial
              situations or any relevant documents. MAX file size 4mb.
            </Typography>
            <img width="100%" src={Image11} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              5. Review details and documents. Edit details if needed and submit.
            </Typography>
            <img width="100%" src={Image12} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              6. Note your lodgement number and track your application.
            </Typography>
            <img width="100%" src={Image13} alt="help image" />
          </VerticalTabPanel>
          <VerticalTabPanel value={value} index={2}>
            <Typography color={colors.primary} variant="h2">
              Track application
            </Typography>
            <Typography color={colors.grey600} fontWeight={400} sx={{ mt: 1.5 }} variant="h6">
              It is now easier to stay on top of your application. Simply
            </Typography>
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              1.Click on the applications to view all applications
            </Typography>
            <img width="100%" src={Image21} alt="help image" />
            <Typography
              color={colors.grey600}
              fontWeight={500}
              sx={{ mt: 2, display: 'inline' }}
              variant="h6"
            >
              2. Applications are sorted based on their status in tabs.
            </Typography>
            <Typography
              color={colors.grey600}
              fontWeight={400}
              sx={{ mt: 2, display: 'inline' }}
              variant="h6"
            >
              {' '}
              In Progress, Drafts, Closed and Rejected.
            </Typography>
            <img width="100%" style={{ marginTop: 8 }} src={Image22} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              3. Click on the application to preview details and status.
            </Typography>
            <img width="100%" src={Image23} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              4. Any application not submitted is automatically saved under the Drafts tab.
            </Typography>
            <img width="100%" src={Image24} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              5. You can open your draft applications to continue filling it and submit.
            </Typography>
            <img width="100%" src={Image25} alt="help image" />
            <Typography color={colors.grey600} fontWeight={500} sx={{ mt: 2, mb: 1 }} variant="h6">
              6. Application status
            </Typography>
            <Box display="flex" alignItems={'center'} mb={1}>
              <Box width="auto">
                <Chip showIcon color={getStatusColor('Draft')} text="Draft" />
              </Box>
              <Typography color={colors.grey600} fontWeight={400} sx={{ ml: 2 }} variant="h6">
                Application has not yet been submitted for review and approval to MAD.
              </Typography>
            </Box>
            <Box display="flex" alignItems={'center'} mb={1}>
              <Box width="auto">
                <Chip
                  showIcon
                  color={getStatusColor('Awaiting approval')}
                  text="Awaiting approval"
                />
              </Box>
              <Typography color={colors.grey600} fontWeight={400} sx={{ ml: 2 }} variant="h6">
                Application is currently awaiting MAD admin or MAD board approvals.
              </Typography>
            </Box>
            <Box display="flex" alignItems={'center'} mb={1}>
              <Box width="auto">
                <Chip showIcon color={getStatusColor('Approved')} text="Approved" />
              </Box>
              <Typography color={colors.grey600} fontWeight={400} sx={{ ml: 2 }} variant="h6">
                Application / funding approved by MAD.
              </Typography>
            </Box>
            <Box display="flex" alignItems={'center'} mb={1}>
              <Box width="auto">
                <Chip showIcon color={getStatusColor('Complete')} text="Complete" />
              </Box>
              <Typography color={colors.grey600} fontWeight={400} sx={{ ml: 2 }} variant="h6">
                Item / service delivered to recipient.
              </Typography>
            </Box>
            <Box display="flex" alignItems={'center'} mb={1}>
              <Box width="auto">
                <Chip showIcon color={getStatusColor('Rejected')} text="Rejected" />
              </Box>
              <Typography color={colors.grey600} fontWeight={400} sx={{ ml: 2 }} variant="h6">
                Application has been rejected by MAD.
              </Typography>
            </Box>
          </VerticalTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Help;
