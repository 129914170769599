import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../theme/Theme';
import ShippingIcon from '@mui/icons-material/LocalShipping';
import ApprovalIcon from '@mui/icons-material/Approval';
import DoneIcon from '@mui/icons-material/Done';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import BlockIcon from '@mui/icons-material/Block';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Status } from '../service/application/ApplicationModels';

interface Props {
  text: string;
  color?: string;
  height?: string;
  fontSize?: string;
  variant?: string;
  showIcon?: boolean;
}

const chipStyle = { color: 'white', height: 14, pt: '4.5px', pl: '2px', mr: -1 };

const getChipIcon = (status: string): React.ReactNode => {
  switch (status) {
    case Status.Approved:
      return <ApprovalIcon sx={chipStyle} />;
    case Status.Rejected:
      return <BlockIcon sx={chipStyle} />;
    case Status.AwaitingApproval:
    case Status.InProgress:
      return <HourglassTopIcon sx={chipStyle} />;
    case Status.WithSupplier:
    case Status.Delivery:
      return <ShippingIcon sx={chipStyle} />;
    case Status.Complete:
      return <DoneIcon sx={chipStyle} />;
    case Status.QuoteNotFinal:
      return <InfoOutlinedIcon sx={chipStyle} />;
    case Status.Draft:
      return <BorderColorOutlinedIcon sx={chipStyle} />;
    default:
      return null;
  }
};

const Chip: FC<Props> = ({ text, color, height, fontSize, variant, showIcon }) => {
  return (
    <Box
      boxSizing="content-box"
      border={variant === 'outlined' ? `1px solid ${color}` : 'none'}
      display="flex"
      bgcolor={variant === 'outlined' ? 'white' : color || colors.primary}
      height={height || 24}
      borderRadius={64}
      px="1px"
    >
      {showIcon && getChipIcon(text)}
      <Typography
        sx={{
          fontWeight: '500',
          fontFamily: 'Roboto',
          px: 1,
          pt: '1px',
          fontSize: fontSize ? fontSize : 13,
          color: variant === 'outlined' ? color : 'white',
        }}
        variant="caption"
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Chip;
