import { Box, InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import Label from './Label';

interface Props {
  meta: any;
  input: any;
  label: string;
  type?: string;
  startAdornment?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: Function;
}

const CustomInput: FC<Props> = ({
  meta,
  input,
  label,
  type,
  startAdornment,
  placeholder,
  disabled,
  onChange,
}) => {
  if (!meta) return <div />;
  return (
    <Box height={72} display="flex" flexDirection="column" alignItems="flex-start">
      <Box display="flex" width="100%">
        {startAdornment === 'phone' && (
          <TextField
            {...input}
            value="+61"
            disabled
            type={'text'}
            size="small"
            sx={{ mat: 6, mr: 2, width: 72 }}
          />
        )}
        <TextField
          name={label}
          error={!!(meta.touched && meta.error)}
          {...input}
          onChange={(e) => {
            input.onChange(e);
            if (onChange) onChange(e);
          }}
          type={type || 'text'}
          size="small"
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          fullWidth
          InputProps={{
            name: label,
            startAdornment:
              startAdornment === 'amount' && input.value ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : null,
          }}
          styles={{ marginTop: 6 }}
        />
      </Box>
      <Label
        style={{ paddingLeft: 14, paddingTop: 4 }}
        error={meta.touched && meta.error}
        text={meta.error}
      />
    </Box>
  );
};

export default CustomInput;
