import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridRowModel,
} from '@mui/x-data-grid';
import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ApplicationTrackingStyles';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import {
  selectApplicationListData,
  postGetAllApplications,
  selectGetAllApplicationsLoading,
} from '../../service/application/ApplicationListSlice';
import Toast from '../../component/Toast';
import Chip from '../../component/Chip';
import {
  dollarFormatter,
  getStatusColor,
  hasAdminPermission,
  hasPractitionerPermission,
} from '../../helper/AppHelper';
import MobileGrid from '../../component/MobileGrid/MobileGrid';
import { TabPanel } from '../../component/TabPanel';
import { Status } from '../../service/application/ApplicationModels';
import {
  searchKeyString,
  selectSearchApplicationData,
  selectSearchApplicationLoading,
} from '../../service/application/SearchSlice';

const filterList = ['All'];

const ApplicationTracking: FC<{}> = ({}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [draftApplications, setDraftApplications] = useState([]);
  const [closedApplications, setClosedApplications] = useState([]);
  const [rejectedApplications, setRejectedApplications] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabProgress, setActiveTabProgress] = useState(0);
  const [open, setOpen] = useState(true);
  const loading = useAppSelector(selectGetAllApplicationsLoading);
  const searchLoading = useAppSelector(selectSearchApplicationLoading);
  const response = useAppSelector(selectApplicationListData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const searchKey = useAppSelector(searchKeyString);
  const searchResult = useAppSelector(selectSearchApplicationData);

  const processData = (allApplications: any) => {
    if (!allApplications) return;
    const applications = allApplications.filter((row: any) => {
      if (
        row.applicationStatus !== Status.Draft &&
        row.applicationStatus !== Status.Complete &&
        row.applicationStatus !== Status.Deleted &&
        row.applicationStatus !== Status.Rejected
      )
        return row;
    });
    const draftApplications = allApplications.filter((row: any) => {
      if (row.applicationStatus === Status.Draft) return row;
    });
    const closedApplications = allApplications.filter((row: any) => {
      if (row.applicationStatus === Status.Complete || row.applicationStatus === Status.Deleted)
        return row;
    });
    const rejectedApplications = allApplications.filter((row: any) => {
      if (row.applicationStatus === Status.Rejected) return row;
    });
    setFilteredApplications(applications);
    setDraftApplications(draftApplications);
    setClosedApplications(closedApplications);
    setRejectedApplications(rejectedApplications);
  };

  useEffect(() => {
    if (searchResult && searchKey) {
      processData(searchResult.results);
    } else {
      const fetchData = async () => {
        const api = postGetAllApplications;
        const response = await dispatch(api({}));
        if (response.type.includes('fulfilled')) {
          processData(response.payload.results);
        }
      };
      fetchData().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  const searchResults = useMemo(
    () =>
      (searchResult && searchResult.results ? searchResult.results : []).map(
        (row: any, i: number) => {
          if (!filterList.includes(row.applicationStatus)) {
            filterList.push(row.applicationStatus);
          }
          return { ...row, id: i };
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResult],
  );

  const rows = useMemo(
    () =>
      (filteredApplications || []).map((row: any, i: number) => {
        if (!filterList.includes(row.applicationStatus)) {
          filterList.push(row.applicationStatus);
        }
        return { ...row, id: i };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [filteredApplications],
  );

  const pendingRows = useMemo(
    () =>
      (draftApplications || []).map((row: any, i: number) => {
        return { ...row, id: i };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [draftApplications],
  );

  const approvedRows = useMemo(
    () =>
      (closedApplications || []).map((row: any, i: number) => {
        return { ...row, id: i };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [closedApplications],
  );

  const rejectedRows = useMemo(
    () =>
      (rejectedApplications || []).map((row: any, i: number) => {
        return { ...row, id: i };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [rejectedApplications],
  );

  const handleOpen = (data: GridRowModel) => {
    if (data.applicationStatus === 'Draft') {
      navigate('/new-application/supplier', { state: { applicationId: data.applicationId } });
    } else {
      navigate(`/new-application/summary/${data.applicationId}`, {
        state: { applicationId: data.applicationId },
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'dateLodged',
      headerName: 'Date',
      width: 115,
      sortable: false,
      align: 'left',
      headerClassName: styles.header,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.dateLodged || params.row.dateCreated}`,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {moment(params.value).format('DD MMM YYYY')}
        </Box>
      ),
    },
    {
      field: 'applicationId',
      headerName: 'Lodgement #',
      sortable: false,
      width: 150,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'applicant',
      headerName: 'Applicant',
      sortable: false,
      width: 210,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => `${params.row.applicantName.fullName || ''}`,
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'cost',
      headerName: 'Cost',
      sortable: false,
      width: 110,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {dollarFormatter(params.value)}
        </Box>
      ),
    },
    {
      field: 'beneficiary',
      headerName: 'Beneficiary',
      sortable: false,
      width: 210,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.beneficiaryName.fullName || ''}`,
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ cursor: 'pointer' }} p={1}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'applicationStatus',
      headerName: 'Status',
      sortable: false,
      description: 'This column has a value getter and is not sortable.',
      width: 183,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box
          sx={{ cursor: 'pointer' }}
          width={180}
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Chip showIcon color={getStatusColor(params.value!)} text={params.value!} />
          </Box>
        </Box>
      ),
    },
  ];

  const handleTabProgressChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setActiveTabProgress(tabIndex);
  };

  const handleTabChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <Box height="95%" minHeight={`calc(100vh - 65px)`}>
      <Box display="flex" height="100%">
        <Box
          sx={{ pl: { xs: 1.5, sm: 12 }, pb: 6, pr: { xs: 2, sm: 0 }, pt: 4 }}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Grid direction="column" sx={{ flexWrap: 'nowrap' }} container>
            <Grid sx={{ maxWidth: '996px !important', width: '100%', px: 0 }} item>
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box>
                  <Typography color="primary" variant="h2">
                    {hasPractitionerPermission() ? 'My Applications' : 'All Applications'}
                  </Typography>
                  {/* <Typography style={{ maxWidth: 560 }} variant="h6" className={styles.label}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo
                    hendrerit mauris ut consectetur.
                  </Typography> */}
                </Box>
                {(hasPractitionerPermission() || hasAdminPermission()) && (
                  <Box>
                    <Button
                      sx={{ width: 200, mr: 2 }}
                      variant="contained"
                      onClick={() => navigate('/new-application/supplier')}
                    >
                      New Application
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} sx={{ maxWidth: `980px !important` }}>
              <Box sx={{ pr: { xs: 0, sm: 0 } }}>
                {loading === 'loading' || searchLoading === 'loading' ? (
                  <Box my={15}>
                    <CircularProgress size={30} />
                  </Box>
                ) : searchResults && searchResults.length && searchKey ? (
                  <Box mt={2}>
                    <Typography style={{ maxWidth: 560 }} variant="h6" className={styles.label}>
                      Search Results
                    </Typography>
                    <DataGrid
                      rows={searchResults}
                      sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontWeight: '600',
                        },
                      }}
                      autoHeight
                      disableColumnMenu
                      disableColumnFilter
                      autoPageSize
                      columns={columns}
                      onRowClick={(params) => handleOpen(params.row)}
                      pageSize={10}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ width: '100%' }}>
                      <Box>
                        <Tabs value={activeTabProgress} onChange={handleTabProgressChange}>
                          <Tab
                            sx={{ fontFamily: 'Roboto', fontWeight: 500 }}
                            label={'In Progress'}
                          />
                          {hasPractitionerPermission() && (
                            <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label="Drafts" />
                          )}
                        </Tabs>
                      </Box>
                      <TabPanel value={activeTabProgress} index={0}>
                        {isMobile ? (
                          <MobileGrid rows={rows} />
                        ) : (
                          <DataGrid
                            rows={rows}
                            sx={{
                              '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                              },
                            }}
                            autoHeight
                            disableColumnMenu
                            disableColumnFilter
                            autoPageSize
                            columns={columns}
                            onRowClick={(params) => handleOpen(params.row)}
                            pageSize={5}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        )}
                      </TabPanel>
                      <TabPanel value={activeTabProgress} index={1}>
                        {isMobile ? (
                          <MobileGrid rows={pendingRows} />
                        ) : (
                          <DataGrid
                            rows={pendingRows}
                            sx={{
                              '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                              },
                            }}
                            autoHeight
                            disableColumnMenu
                            disableColumnFilter
                            autoPageSize
                            columns={columns}
                            onRowClick={(params) => handleOpen(params.row)}
                            pageSize={5}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        )}
                      </TabPanel>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Box>
                        <Tabs value={activeTab} onChange={handleTabChange}>
                          <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label="Closed" />
                          <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label="Rejected" />
                        </Tabs>
                      </Box>
                      <TabPanel value={activeTab} index={0}>
                        {isMobile ? (
                          <MobileGrid rows={approvedRows} />
                        ) : (
                          <DataGrid
                            rows={approvedRows}
                            sx={{
                              '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                              },
                            }}
                            autoHeight
                            disableColumnMenu
                            disableColumnFilter
                            autoPageSize
                            columns={columns}
                            onRowClick={(params) => handleOpen(params.row)}
                            pageSize={5}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        )}
                      </TabPanel>
                      <TabPanel value={activeTab} index={1}>
                        {isMobile ? (
                          <MobileGrid rows={rejectedRows} />
                        ) : (
                          <DataGrid
                            rows={rejectedRows}
                            sx={{
                              '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                              },
                            }}
                            autoHeight
                            disableColumnMenu
                            disableColumnFilter
                            autoPageSize
                            getCellClassName={() => 'application-table-cell'}
                            columns={columns}
                            onRowClick={(params) => handleOpen(params.row)}
                            pageSize={5}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        )}
                      </TabPanel>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }} width={168} bgcolor="#FFD666"></Box>
      </Box>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        autoHideDuration={3000}
        errorMsg="Something went wrong"
      />
      <Toast
        loading={searchLoading}
        response={searchResult}
        open={open}
        setOpen={setOpen}
        autoHideDuration={3000}
        errorMsg="Something went wrong"
      />
    </Box>
  );
};

export default ApplicationTracking;
