import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getUrl } from '../../service/AxiosSetup';

export interface RegisterState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: RegisterState = {
  status: 'idle',
  data: null,
};

export const getTitle = createAsyncThunk('register', async (__, { rejectWithValue }) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios.get(getUrl('resource/name-title', {}));
    return response.data;
  } catch (e: any) {
    if (!e.response) {
      throw e;
    }
    return rejectWithValue(e.response.data);
  }
});

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTitle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTitle.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload as string;
      })
      .addCase(getTitle.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.register.status;
export const selectData = (state: RootState) => state.register.data;

export default registerSlice.reducer;
