import React from 'react';
import ErrorPage from './ErrorPage';

interface Props {
  children: React.ReactElement;
}

interface State {
  error: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error: any) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorPage errorMsg={error} />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default ErrorBoundary;
