import { Box, CircularProgress, Dialog, Typography } from '@mui/material';
import React, { Dispatch, FC } from 'react';
import { colors } from '../theme/Theme';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadingIcon from '@mui/icons-material/Downloading';

interface Props {
  open: boolean;
  setOpen: Dispatch<any>;
  link: string | undefined;
  name: string | undefined;
  setDocLink: Dispatch<any>;
  setDocName: Dispatch<any>;
}

const DocPreview: FC<Props> = ({ open, setOpen, link, name, setDocLink, setDocName }) => {
  const lowerCaseFileName = name?.toLowerCase();
  const isDoc = lowerCaseFileName?.endsWith('.doc') || lowerCaseFileName?.endsWith('.docx');
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setDocLink(undefined);
        setDocName(undefined);
      }}
    >
      <Box pt={1} bgcolor={colors.border}>
        <Box display="flex" justifyContent="space-between" pb={1} pl={2} pt={1} width="100%">
          <Box color={colors.grey600}>{link ? name : 'Loading'}</Box>
          <ClearIcon
            onClick={() => {
              setOpen(false);
              setDocLink(undefined);
              setDocName(undefined);
            }}
            sx={{ mr: 2, cursor: 'pointer', color: colors.grey600 }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={isDoc ? '20vh' : '80vh'}
          minWidth="600px"
        >
          {isDoc && (
            <Box pb={2} textAlign={'center'}>
              <DownloadingIcon color="primary" fontSize="large" />
              <Typography color={colors.grey600}>File download started</Typography>
            </Box>
          )}
          {link ? (
            <iframe
              style={{
                border: 'none',
                minHeight: '40vh',
                padding: 10,
                minWidth: '600px',
                height: '80vh',
                display: isDoc ? 'none' : 'block',
              }}
              src={link}
            />
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocPreview;
