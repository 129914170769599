import { Alert, Snackbar } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  loading: string;
  response: any;
  open: boolean;
  setOpen: (state: boolean) => void;
  autoHideDuration?: number;
  successMsg?: string;
  errorMsg?: string;
}

const Toast: FC<Props> = ({
  loading,
  response,
  autoHideDuration,
  open,
  setOpen,
  successMsg,
  errorMsg,
}) => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open && (loading === 'failed' || !!(successMsg && loading === 'success'))}
      autoHideDuration={autoHideDuration || 6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={loading === 'failed' ? 'error' : 'success'}
        sx={{ width: '100%' }}
      >
        {loading === 'failed'
          ? errorMsg || response?.message || 'Something went wrong'
          : successMsg || 'Success!'}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
