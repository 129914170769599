import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/Theme';

export const useStyles = makeStyles({
  summary: {
    marginBottom: 6,
    textAlign: 'left',
  },
  submitBtn: {
    marginTop: 0,
  },
  editBtn: {
    margin: 0,
    padding: 0,
    height: 20,
    minWidth: 26,
    color: colors.errorText,
  },
  draftBtn: {
    color: colors.grey600,
    textTransform: 'none',
    textDecoration: 'underline',
  },
  backBtn: {
    color: colors.black,
    fontWeight: 400,
    margin: 0,
    paddingLeft: 0,
    marginLeft: -12,
  },
  label: {
    marginBottom: 20,
  },
  icon: {
    fill: colors.primary,
  },
  iconDisabled: {
    fill: colors.border,
  },
  paper: {
    marginTop: 7,
    boxShadow: 'none',
    marginLeft: 38,
  },
  list: {
    padding: 0,
  },
});
