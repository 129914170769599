import { BrowserHistory } from 'history';
import React, { useContext, FC, useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export function usePathname(): string {
  const [state, setState] = React.useState<string>(window.location.pathname);

  const navigation = useContext(UNSAFE_NavigationContext).navigator as BrowserHistory;
  React.useLayoutEffect(() => {
    if (navigation) {
      navigation.listen((locationListener) => setState(locationListener.location.pathname));
    }
  }, [navigation]);

  return state;
}

export const ScrollToTop: FC<{}> = () => {
  const pathname = usePathname();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
