import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../AxiosSetup';

export interface SearchApplicationState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: SearchApplicationState = {
  status: 'idle',
  data: null,
};

export const postSearchApplication = createAsyncThunk(
  'search application',
  async (searchString: string, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(
        getUrl('application/search', {}),
        { searchString },
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const postSearchApplicationSlice = createSlice({
  name: 'search application',
  initialState,
  reducers: {
    clearResults: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSearchApplication.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSearchApplication.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postSearchApplication.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const searchKeySlice = createSlice({
  name: 'search key',
  initialState: { value: '' },
  reducers: {
    setSearchKey: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSearchKey } = searchKeySlice.actions;
export const { clearResults } = postSearchApplicationSlice.actions;
export const searchKeyReducer = searchKeySlice.reducer;
export const searchKeyString = (state: RootState) => state.searchKey.value;

export const selectSearchApplicationLoading = (state: RootState) => state.searchApplication.status;
export const selectSearchApplicationData = (state: RootState) => state.searchApplication.data;

export default postSearchApplicationSlice.reducer;
