import { Box, IconButton, Grid, Typography, CircularProgress, Button } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './ApplicationListStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import {
  postGetAllApplications,
  selectGetAllApplicationsLoading,
  selectGetAllApplicationsData,
} from '../../service/application/ApplicationListSlice';
import Toast from '../../component/Toast';
import Filter from '../../component/Filter/Filter';
import { colors } from '../../theme/Theme';
import Chip from '../../component/Chip';
import { dollarFormatter, getStatusColor } from '../../helper/AppHelper';

const filterList = ['Draft'];

const ApplicationList: FC<{}> = ({}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [activeFilter, setActiveFilter] = useState(['Draft']);
  const [open, setOpen] = useState(true);
  const loading = useAppSelector(selectGetAllApplicationsLoading);
  const response = useAppSelector(selectGetAllApplicationsData);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(postGetAllApplications({ status: activeFilter[0] }));
      if (response.type.includes('fulfilled')) {
        setApplications(response.payload.results);
      }
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = (applications || []).map((row: any, i: number) => {
    if (!filterList.includes(row.applicationStatus)) {
      filterList.push(row.applicationStatus);
    }
    return { ...row, id: i };
  });

  const handleFilter = (filter: string) => {
    setActiveFilter([filter]);
  };

  const columns: GridColDef[] = [
    {
      field: 'dateLodged',
      headerName: 'Date',
      width: 115,
      sortable: false,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box p={1}>{moment(params.value).format('DD MMM YYYY')}</Box>
      ),
    },
    {
      field: 'applicationId',
      headerName: 'Lodgement #',
      width: 150,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => <Box p={1}>{params.value}</Box>,
    },
    {
      field: 'applicant',
      headerName: 'Applicant',
      width: 175,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.applicantName.firstName || ''} ${params.row.applicantName.lastName || ''}`,
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => <Box p={1}>{params.value}</Box>,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 135,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box p={1}>{dollarFormatter(params.value)}</Box>
      ),
    },
    {
      field: 'beneficiary',
      headerName: 'Beneficiary',
      width: 175,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.beneficiaryName.firstName || ''} ${
          params.row.beneficiaryName.lastName || ''
        }`,
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => <Box p={1}>{params.value}</Box>,
    },
    {
      field: 'applicationStatus',
      headerName: 'Status',
      description: 'This column has a value getter and is not sortable.',
      width: 170,
      align: 'left',
      headerClassName: styles.header,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box width={180} p={1} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Chip color={getStatusColor(params.value!)} text={params.value!} />
          </Box>
          <IconButton size="small" style={{ marginLeft: 16 }} tabIndex={params.hasFocus ? 0 : -1}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box height="90%" minHeight={`calc(100vh - 65px)`}>
      <Box display="flex" height="100%">
        <Box
          sx={{ pl: { xs: 1.5, sm: 12 }, pb: 6, pr: { xs: 2, sm: 0 }, pt: 4 }}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Grid direction="row" container>
            <Grid sx={{ maxWidth: '922px', width: '100%', px: 3 }} item>
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box>
                  <Typography color="primary" variant="h2">
                    All Applications
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{ width: 200 }}
                    variant="contained"
                    onClick={() => navigate('/new-application/supplier')}
                  >
                    New Application
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} sx={{ maxWidth: `922px !important` }}>
              <Box sx={{ pr: { xs: 0, sm: 0 } }}>
                {loading === 'loading' ? (
                  <Box my={15}>
                    <CircularProgress size={30} />
                  </Box>
                ) : (
                  <Box>
                    <Box mb={2}>
                      <Filter
                        filters={filterList}
                        activeFilter={activeFilter}
                        handleFilter={handleFilter}
                        activeFilterColor={colors.pending}
                      />
                      <DataGrid
                        rows={rows}
                        autoHeight
                        autoPageSize
                        columns={columns}
                        pageSize={5}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }} width={168} bgcolor="#FFD666"></Box>
      </Box>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        autoHideDuration={3000}
        errorMsg="Something went wrong"
      />
    </Box>
  );
};

export default ApplicationList;
