import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/Theme';

export const useStyles = makeStyles({
  beneficiary: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.pageBorder,
  },
  header: {
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  cost: {
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
});
