import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dollarFormatter, getStatusColor } from '../../helper/AppHelper';
import { colors } from '../../theme/Theme';
import Chip from '../Chip';
import { useStyles } from './MobileGridStyles';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

interface Props {
  rows: any;
  rowsPerPage?: number;
  hidePagination?: boolean;
}

const MobileGrid: FC<Props> = ({ rows, rowsPerPage = 5, hidePagination = false }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageRows, setPageRows] = useState([]);

  useEffect(() => {
    setPageRows(rows.slice(0, rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleOpen = (data: any) => {
    if (data.applicationStatus === 'Draft') {
      navigate('/new-application/supplier', { state: { applicationId: data.applicationId } });
    } else {
      navigate(`/new-application/summary/${data.applicationId}`, {
        state: { applicationId: data.applicationId },
      });
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setPageRows(rows.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage));
  };

  if (!rows || rows.length === 0) return <div>No data</div>;
  return (
    <Box display="flex" flexDirection="column" pb={1.5}>
      {pageRows.map((row: any, i: number) => (
        <Accordion
          key={`row-${row.applicatonId}-${i}`}
          elevation={0}
          sx={{ background: 'none' }}
          disableGutters
        >
          <AccordionSummary sx={{ p: 0 }}>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              key={`mobile-row-${row.applicationId}`}
            >
              <Box py={0.5} display="flex" justifyContent="space-between">
                <Typography className={styles.header}>
                  {moment(row.dateLodged).format('DD MMM YYYY')}
                </Typography>
                <Chip
                  showIcon
                  color={getStatusColor(row.applicationStatus)}
                  text={row.applicationStatus}
                />
              </Box>
              <Box py={0.5} display="flex" justifyContent="space-between">
                <Typography className={styles.beneficiary}>
                  {row.beneficiaryName.fullName.trim() || 'Beneficiary N/A'}
                </Typography>
                <Typography className={styles.cost}>{dollarFormatter(row.cost)}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box width="100%">
              <Stack direction="row">
                <Box minWidth="40%" maxWidth="40%" textAlign="left">
                  <Typography
                    sx={{
                      fontSize: 14,
                      textAlign: 'left',
                      color: colors.pageBorder,
                      fontWeight: 600,
                    }}
                  >
                    Applicant
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      wordBreak: 'break-word',
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      color: colors.grey800,
                      fontSize: 15,
                      fontWeight: 400,
                      marginLeft: 2,
                      maxWidth: { xs: 180, sm: 250 },
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {row.applicantName.firstName} {row.applicantName.lastName}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box minWidth="40%" maxWidth="40%" textAlign="left">
                  <Typography
                    sx={{
                      fontSize: 14,
                      textAlign: 'left',
                      color: colors.pageBorder,
                      fontWeight: 600,
                    }}
                  >
                    Lodgement #
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      wordBreak: 'break-word',
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      color: colors.grey800,
                      fontSize: 15,
                      fontWeight: 400,
                      marginLeft: 2,
                      maxWidth: { xs: 180, sm: 250 },
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {row.applicationId}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box width="50px" display="flex" position="relative">
              <IconButton
                onClick={() => handleOpen(row)}
                size="large"
                style={{
                  marginLeft: 0,
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  padding: 10,
                  background: colors.sectionBorder,
                }}
              >
                <ArrowForwardOutlinedIcon />
              </IconButton>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      {!hidePagination && (
        <TablePagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[]}
          onPageChange={handleChangePage}
        />
      )}
    </Box>
  );
};

export default MobileGrid;
