import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/mad_logo.png';
import { colors } from '../../theme/Theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  hideLeftLine?: boolean;
  errorMsg?: string;
}

const ErrorPage: FC<Props> = ({ hideLeftLine, errorMsg }) => {
  const navigate = useNavigate();
  return (
    <Box height="100vh" width="100vw">
      <Box
        borderLeft={hideLeftLine ? '' : `4px solid ${colors.pageBorder}`}
        height="100vh"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          title="Home"
          onClick={() => navigate('/')}
          width={100}
          height={44}
          src={logo}
          style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
        />
        <Grid style={{ display: 'flex' }} container>
          <Grid item xs={11} sm={6} md={4} lg={3}>
            <Box
              sx={{
                position: 'fixed',
                transform: 'translate(-50%, -90%)',
                top: '50%',
                left: '50%',
                width: { xs: '95%', sm: 680 },
                p: 4,
              }}
            >
              <Box display="flex" alignItems="center">
                <WarningRoundedIcon sx={{ width: 45, height: 45, mr: 1 }} />
                <Typography sx={{ fontSize: 24, fontWeight: 700 }} variant="h3">
                  Oops! An error has occurred with the page.
                </Typography>
              </Box>
              <Box ml={7}>
                <Typography
                  sx={{ color: colors.grey700, fontSize: 14, fontWeight: 500, mt: 2 }}
                  variant="h6"
                >
                  The page you are looking for either does not exist, was moved or is temporarily
                  unavailable.
                </Typography>
                <Typography
                  onClick={() => {
                    navigate('/login');
                  }}
                  sx={{
                    color: colors.grey700,
                    mt: 1,
                    fontSize: 14,
                    fontWeight: 500,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  variant="h6"
                >
                  Go Back
                </Typography>
                <Typography
                  sx={{
                    color: colors.grey600,
                    mt: 1,
                    fontSize: 14,
                    fontWeight: 500,
                    wordBreak: 'break-all',
                  }}
                  variant="h6"
                >
                  {errorMsg && (
                    <span>
                      Error msg
                      <Tooltip title={errorMsg || ''}>
                        <InfoOutlinedIcon
                          sx={{ width: 15, height: 15, mr: '5px', ml: '3px', mb: '-3px' }}
                        />
                      </Tooltip>
                    </span>
                  )}
                  For technical difficulties please contact <u>techhelp@madfoundation.com.au</u>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ErrorPage;
