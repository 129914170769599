import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../AxiosSetup';
import { SupplierObj } from './ApplicationModels';

export interface SupplierState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: SupplierState = {
  status: 'idle',
  data: null,
};

export const postSupplier = createAsyncThunk(
  'Supplier',
  async (data: SupplierObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(getUrl('application/save/order', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const SupplierSlice = createSlice({
  name: 'Supplier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSupplier.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSupplier.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postSupplier.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectSaveSupplierLoading = (state: RootState) => state.saveSupplier.status;
export const selectSupplierData = (state: RootState) => state.saveSupplier.data;

export default SupplierSlice.reducer;
