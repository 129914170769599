import { Box, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, style } = props;

  return (
    <div role="tabpanel" hidden={value !== index} style={style}>
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function VerticalTabPanel(props: TabPanelProps) {
  const { children, value, index, style } = props;

  return (
    <div role="tabpanel" hidden={value !== index} style={style}>
      {value === index && (
        <Box width="70%" sx={{ py: 5, px: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
