import {
  Box,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
} from '@mui/material';
import React, { Dispatch, FC, useState, useEffect } from 'react';
import { useStyles } from './BeneficiaryStyles';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import { useLocation, useNavigate } from 'react-router-dom';
import Label from '../../component/Label';
import { styled } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { deleteFile, postFileUpload, selectLoading } from '../../service/upload/FileUploadSlice';
import { colors } from '../../theme/Theme';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  formatPhone,
  GetKeyByValue,
  ValidateDate,
  ValidateFiles,
  ValidatePhone,
} from '../../helper/AppHelper';
import createDecorator from 'final-form-focus';
import { SupplierForm } from '../supplier/Supplier';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import FileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { NamePathMapper } from '../application/Application';
import {
  postBeneficiary,
  selectSaveBeneficiaryLoading,
} from '../../service/application/BeneficiarySlice';
import { BeneficiaryObj } from '../../service/application/ApplicationModels';
import { LoadingButton } from '@mui/lab';
import DocPreview from '../../component/DocPreview';
import { getLink } from '../../service/upload/ViewFileSlice';

export interface BeneficiaryForm {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  preferredName: string | null | undefined;
  dob: string | null | undefined;
  parentFirstName: any | string | null | undefined;
  parentLastName: any | string | null | undefined;
  parentEmail: string | null | undefined;
  parentPhone: string | null | undefined;
  file: any | null | undefined;
  engSpeaking: string | null | undefined;
  fileIds: string[];
}

const Input = styled('input')({
  display: 'none',
});

interface Props {
  supplierDetails: SupplierForm[];
  applicationId: string | undefined;
  beneficiaryDetails: BeneficiaryForm;
  setBeneficiaryDetails: Dispatch<any>;
  fileNamePathMapper: NamePathMapper;
  setFileNamePathMapper: Dispatch<any>;
  fileIds: string[];
  setFileIds: Dispatch<any>;
  setOpenBeneficiary: Dispatch<any>;
  modalOpen?: boolean;
  setModalType?: Dispatch<any>;
  setModalOpen?: Dispatch<any>;
  getData: () => void;
}

const focusOnErrors: any = createDecorator();

const Beneficiary: FC<Props> = ({
  beneficiaryDetails,
  setBeneficiaryDetails,
  supplierDetails,
  fileNamePathMapper,
  setFileNamePathMapper,
  fileIds,
  setFileIds,
  setOpenBeneficiary,
  modalOpen,
  setModalOpen,
  setModalType,
  getData,
  applicationId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!supplierDetails || supplierDetails.length === 0) {
      navigate('/new-application/supplier');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = useStyles();
  const [engSpeaking, setEngSpeaking] = useState<string | undefined>(undefined);
  const [fileErrors, setFileErrors] = useState<string | boolean>(false);
  const fileLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const saveLoading = useAppSelector(selectSaveBeneficiaryLoading);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [docLink, setDocLink] = useState<string | undefined>(undefined);
  const [docName, setDocName] = useState<string | undefined>(undefined);

  const getPostData = (values: BeneficiaryForm) => {
    const {
      parentEmail,
      parentFirstName,
      parentLastName,
      parentPhone,
      dob,
      firstName,
      lastName,
      preferredName,
    } = values;
    const data: BeneficiaryObj = {
      applicationId: location.state
        ? (location.state as { applicationId: string }).applicationId
        : applicationId!,
      beneficiaryDetails: {
        parentEmail: parentEmail!,
        dateOfBirth: dob!,
        beneficiaryName: {
          firstName: firstName!,
          lastName: lastName!,
          preferredName: preferredName!,
        },
        parentName: {
          firstName: parentFirstName,
          lastName: parentLastName,
        },
        phoneNo: formatPhone(parentPhone!),
        englishSpeaking: engSpeaking === 'Yes',
        languageSpoken: engSpeaking === 'Yes' ? null : '',
        documents: fileIds.map((file: string) => ({
          id: file,
          name: GetKeyByValue(fileNamePathMapper, file),
        })),
      },
    };
    return data;
  };

  const handlePreview = async (name: string, path: string) => {
    setOpenPreview(true);
    const response = await dispatch(getLink({ path }));
    if (response.type.includes('fulfilled')) {
      setDocName(name);
      setDocLink(response.payload.message);
    }
  };

  const onSubmit = async (values: BeneficiaryForm, back?: boolean) => {
    const beneficiary = { ...values, engSpeaking: engSpeaking, fileIds };
    setBeneficiaryDetails(beneficiary);
    if (back) {
      navigate('/new-application/supplier');
      return;
    }
    const response = await dispatch(postBeneficiary(getPostData(values)!));
    if (response.type.includes('fulfilled')) {
      setOpenBeneficiary(true);
      if (modalOpen && setModalOpen && setModalType) {
        setModalOpen(false);
        setModalType(null);
        getData();
        return;
      }
      navigate(`/new-application/summary/${response.payload.applicationId}`);
    }
  };

  const getInitialData = () => {
    if (beneficiaryDetails && beneficiaryDetails.firstName) {
      if (!engSpeaking) {
        setEngSpeaking(beneficiaryDetails.engSpeaking!);
      }
      return beneficiaryDetails;
    } else return undefined;
  };

  const validate = (value: BeneficiaryForm) => {
    const {
      firstName,
      lastName,
      dob,
      parentFirstName,
      parentLastName,
      parentEmail,
      parentPhone,
      engSpeaking,
      file,
    } = value;
    const fileValidator = ValidateFiles(file)[0] ? undefined : ValidateFiles(file)[1];
    const error = {
      firstName: firstName ? undefined : 'Please enter first name',
      lastName: lastName ? undefined : 'Please enter last name',
      dob: ValidateDate(dob) ? undefined : 'Please enter a valid date of birth',
      file:
        fileValidator || fileValidator === undefined
          ? fileValidator
          : 'Please upload a supplementing doc',
      engSpeaking: engSpeaking ? undefined : 'Please select YES or NO',
      parentFirstName: parentFirstName ? undefined : 'Please enter parent or carers first name',
      parentLastName: parentLastName ? undefined : 'Please enter parent or carers last name',
      parentEmail: parentEmail ? undefined : 'Please enter a valid email',
      parentPhone: ValidatePhone(parentPhone) ? undefined : 'Please enter a valid phone no',
    };
    return error;
  };

  const validateFile = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const validator = ValidateFiles(e!.target.files!);
    const errors = validator[0] ? false : validator[1] || 'Please upload a supplementing doc';
    setFileErrors(errors);
    if (!validator[0]) {
      return false;
    }
    return true;
  };

  const handleFileUploaded = async (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const files = e!.target!.files!;
    const fileIdList = [...fileIds];
    for (let i = 0; i < files.length; i++) {
      const formData: any = new FormData();
      // eslint-disable-next-line
      formData.append('File', files[i]);
      // eslint-disable-next-line
      formData.append('Name', files[i].name);
      const response = await dispatch(postFileUpload(formData));
      if (response.type.includes('fulfilled')) {
        fileIdList.push(response.payload.path);
        const mapper: NamePathMapper = fileNamePathMapper;
        mapper[files[i].name] = response.payload.path;
        setFileNamePathMapper(mapper);
        if (i + 1 === files.length) {
          setFileIds(fileIdList);
        }
      }
    }
  };

  const handleRemove = async (index: number, setValue: Function, files: FileList) => {
    const temp = [...fileIds];
    const response = await dispatch(deleteFile({ path: temp[index] }));
    if (response.type.includes('fulfilled') || response.type.includes('rejected')) {
      temp.splice(index, 1);
      setFileIds(temp);
      const dt = new DataTransfer();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
      }
      setValue('file', dt.files.length ? dt.files : null);
    }
  };

  const getFileNames = (files: FileList, error: boolean, setValue: Function) => {
    const box = [];
    for (let i = 0; i < files.length; i++) {
      box.push(
        <Box
          sx={{ marginTop: { xs: -2, sm: 0 }, marginBottom: { xs: 4, sm: 2 } }}
          key={`file-${files[i].name}`}
          height={18}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex">
            <FileOutlinedIcon color={error ? 'error' : 'primary'} />
            <Typography
              title="View"
              onClick={() => {
                handlePreview(files[i].name, fileNamePathMapper[files[i].name] || files[i].type);
              }}
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                fontSize: 16,
                textOverflow: 'ellipsis',
                maxWidth: modalOpen ? 180 : 240,
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                height: 24,
                cursor: 'pointer',
                color: `${error ? colors.errorText : colors.grey700}`,
                ':hover': {
                  textDecoration: 'underline',
                  textDecorationColor: colors.primary,
                  color: colors.primary,
                },
              }}
            >
              {files[i].name || 'Uploaded File'}
            </Typography>
          </Box>
          <Box pr={1}>
            {fileLoading === `loading-${files[i].name}` ||
            fileLoading === `deleting-${fileNamePathMapper[files[i].name]}` ? (
              <CircularProgress size="15px" />
            ) : (
              <IconButton
                sx={{ p: '0px 4px 3px 4px', m: 0 }}
                onClick={() => handleRemove(i, setValue, files)}
              >
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            )}
          </Box>
        </Box>,
      );
    }
    return box;
  };

  return (
    <Box pt={modalOpen ? 0 : 2}>
      <Grid style={{ display: 'flex', flexDirection: 'column' }} container>
        <Grid item xs={11} sm={10} md={10} lg={8} xl={8} style={{ maxWidth: 560 }}>
          <Form
            onSubmit={(v) => onSubmit(v)}
            decorators={[focusOnErrors]}
            validate={validate}
            initialValues={getInitialData()}
            mutators={{
              setValue: (args, state, utils) => {
                utils.changeValue(state, args[0], () => args[1]);
              },
            }}
            keepDirtyOnReinitialize
            render={({
              handleSubmit,
              values,
              form: {
                mutators: { setValue },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                {!modalOpen && (
                  <>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Button
                        onClick={() => onSubmit(values, true)}
                        className={styles.backBtn}
                        variant="text"
                        disabled={fileLoading.includes('loading')}
                      >
                        <ArrowBackOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                        Back
                      </Button>
                    </Box>
                    <Typography color="primary" variant="h2">
                      New application
                    </Typography>
                    <Typography variant="h6" className={styles.label}>
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo
                      hendrerit mauris ut consectetur. */}
                    </Typography>
                  </>
                )}
                <Grid
                  style={{ background: colors.white }}
                  item
                  sx={{
                    padding: modalOpen ? '10px' : { xs: '24px 16px 32px 12px', sm: '30px 48px' },
                  }}
                >
                  <Typography className={styles.subText}>
                    {modalOpen ? 'Edit details' : 'Beneficiary and carer details'}
                  </Typography>
                  {/* <Typography variant="h6" className={styles.label}>
                    {modalOpen
                      ? ''
                      : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo hendrerit mauris ut consectetur.'}
                  </Typography> */}
                  <Typography variant="subtitle1">Beneficiary details</Typography>
                  <Field
                    name="firstName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Beneficiary’s first name" />
                    )}
                  />
                  <Field
                    name="lastName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Beneficiary’s last name" />
                    )}
                  />
                  <Field
                    name="preferredName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Preferred name (optional)" />
                    )}
                  />
                  <Field
                    name="dob"
                    render={({ input, meta }) => (
                      <CustomInput
                        placeholder="dd/mm/yyyy"
                        input={input}
                        meta={meta}
                        label="Date of birth"
                      />
                    )}
                  />
                  <Typography variant="subtitle1">Parents or primary carers details</Typography>
                  <Field
                    name="parentFirstName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Parent or carers first name" />
                    )}
                  />
                  <Field
                    name="parentLastName"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Parent or carers last name" />
                    )}
                  />
                  <Field
                    name="parentEmail"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Email" />
                    )}
                  />
                  <Field
                    name="parentPhone"
                    render={({ input, meta }) => (
                      <CustomInput input={input} startAdornment="phone" meta={meta} label="Phone" />
                    )}
                  />
                  <Field
                    type="checkbox"
                    name="engSpeaking"
                    render={({ input, meta }) => (
                      <Box height={72}>
                        <Label
                          error={meta.touched && meta.error}
                          alwaysShow
                          size={16}
                          text="English speaking"
                        />
                        <Box display="flex" pl={'2px'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={engSpeaking === 'Yes'}
                                onChange={(e) => {
                                  input.onChange(e);
                                  setEngSpeaking(e.target.checked ? 'Yes' : undefined);
                                }}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={engSpeaking === 'No'}
                                onChange={(e) => {
                                  input.onChange(e);
                                  setEngSpeaking(e.target.checked ? 'No' : undefined);
                                }}
                              />
                            }
                            label="No"
                          />
                        </Box>
                        {meta.touched && meta.error && (
                          <Label
                            style={{
                              marginTop: -10,
                              marginBottom: 10,
                              paddingLeft: 14,
                              paddingTop: 4,
                            }}
                            error={meta.touched && meta.error}
                            text={meta.touched && meta.error}
                          />
                        )}
                      </Box>
                    )}
                  />
                  <Typography variant="subtitle1">Supporting documents</Typography>
                  <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                    <Field
                      name="file"
                      type="file"
                      render={({ input, meta }) => (
                        <Box sx={{ marginBottom: { xs: 0, sm: 1 } }}>
                          {input.value && input.value.length && (
                            <Box sx={{ mt: { xs: 3, sm: 1 }, mb: { xs: 0, sm: 3 } }}>
                              {getFileNames(
                                input.value,
                                (meta.touched && meta.error) || fileErrors,
                                setValue,
                              )}
                            </Box>
                          )}
                          <div
                            className={
                              (meta.touched && meta.error) || fileErrors
                                ? styles.dropBoxError
                                : styles.dropBox
                            }
                          >
                            <label
                              style={{ width: '100%', cursor: 'pointer' }}
                              htmlFor="contained-button-file"
                            >
                              <Input
                                onChange={(e) => {
                                  const valid = validateFile(e);
                                  if (!valid) return;
                                  handleFileUploaded(e);
                                  input.onChange(
                                    Array.from(e.target.files!).concat(Array.from(input.value)),
                                  );
                                }}
                                id="contained-button-file"
                                multiple
                                type="file"
                              />
                              <Box
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                px={1.5}
                              >
                                <Typography
                                  variant="h5"
                                  sx={{
                                    textOverflow: 'ellipsis',
                                    maxWidth: 160,
                                    whiteSpace: 'nowrap',
                                    overflowX: 'hidden',
                                  }}
                                  color={
                                    (meta.touched && meta.error) || fileErrors
                                      ? colors.errorText
                                      : 'action'
                                  }
                                >
                                  Upload Documents
                                </Typography>
                                <FileUploadIcon
                                  color={
                                    (meta.touched && meta.error) || fileErrors ? 'error' : 'action'
                                  }
                                />
                              </Box>
                            </label>
                          </div>

                          <Label
                            error={(meta.touched && meta.error) || fileErrors}
                            style={
                              (meta.touched && meta.error) || fileErrors
                                ? { marginTop: -12, marginBottom: 8, marginLeft: 12 }
                                : {}
                            }
                            text={fileErrors || meta.error}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                  <Typography variant="h5">
                    Upload any documents relating to family circumstances, financial situation,
                    medical condition etc.
                  </Typography>
                </Grid>
                <LoadingButton
                  variant="contained"
                  id="sad"
                  sx={{ width: { xs: '100%', sm: 180 }, mb: modalOpen ? '5px' : '0px' }}
                  type="submit"
                  loading={saveLoading === 'loading'}
                  disabled={fileLoading.includes('loading')}
                  className={styles.nextBtn}
                  fullWidth
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                >
                  {modalOpen ? 'Save' : 'Next'}
                </LoadingButton>
              </form>
            )}
          />
        </Grid>
        <DocPreview
          open={openPreview}
          setOpen={setOpenPreview}
          link={docLink}
          name={docName || 'Uploaded File'}
          setDocLink={setDocLink}
          setDocName={setDocName}
        />
      </Grid>
    </Box>
  );
};

export default Beneficiary;
