import { Box, Grid, Typography, Button } from '@mui/material';
import React, { FC } from 'react';
import logo from '../../assets/images/mad_logo.png';
import { useStyles } from './ForgotPasswordStyles';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import { ValidateEmail } from '../../helper/AppHelper';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectData,
  selectLoading,
  postForgotPassword,
} from '../../service/forgotPassword/ForgotPasswordSlice';
import { LoadingButton } from '@mui/lab';
import Toast from '../../component/Toast';
import { colors } from '../../theme/Theme';

interface ForgotPasswordForm {
  email: string | null | undefined;
}

const ForgotPassword: FC<{}> = () => {
  const styles = useStyles();
  const loading = useAppSelector(selectLoading);
  const response = useAppSelector(selectData);
  const [open, setOpen] = React.useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values: ForgotPasswordForm) => {
    const { email } = values;
    const response = await dispatch(postForgotPassword({ email: email }));
    if (response.type.includes('fulfilled')) {
      navigate('/verify-forgot-password', { state: email });
    }
  };

  const validate = (values: ForgotPasswordForm) => {
    const errors: ForgotPasswordForm = {
      email: ValidateEmail(values.email) ? undefined : 'Please enter a valid email address',
    };
    return errors;
  };

  return (
    <Box
      borderLeft={`4px solid ${colors.pageBorder}`}
      height="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <img
        title="Go to Login"
        onClick={() => navigate('/login')}
        width={100}
        height={44}
        src={logo}
        style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
      />
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid item xs={11} sm={6} md={4} lg={3}>
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: { xs: '100%', sm: 420 },
              px: { xs: 1, sm: 5 },
              py: 4,
            }}
          >
            <Typography className={styles.login} variant="h3">
              Forgot password
            </Typography>
            <Typography variant="h6" className={styles.label}>
              Let's help you get logged back in.
            </Typography>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Enter your email address" />
                    )}
                  />
                  <Typography variant="h6" className={styles.footer}>
                    Check your email and click the link to reset your password. It may take up to 5
                    minutes to receive the link.
                  </Typography>
                  <LoadingButton
                    loading={loading === 'loading'}
                    variant="contained"
                    type="submit"
                    className={styles.submitBtn}
                    fullWidth
                    color="primary"
                  >
                    Send recovery code
                  </LoadingButton>
                  <Box display="flex" justifyContent="center" alignItems="baseline">
                    <Typography variant="h6">Have an account?</Typography>
                    <Button
                      onClick={() => navigate('/')}
                      className={styles.loginBtn}
                      color="inherit"
                      variant="text"
                    >
                      Sign in instead
                    </Button>
                  </Box>
                </form>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        successMsg="Otp sent on your email, please check your inbox."
      />
    </Box>
  );
};

export default ForgotPassword;
