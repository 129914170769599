import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../../service/AxiosSetup';
import { ApplicationPayload } from './ApplicationModels';

export interface NewApplicationState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: NewApplicationState = {
  status: 'idle',
  data: null,
};

export const postNewApplication = createAsyncThunk(
  'newApplication',
  async (data: ApplicationPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(getUrl('application/save', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const newApplicationSlice = createSlice({
  name: 'newApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postNewApplication.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNewApplication.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postNewApplication.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectSaveApplicationLoading = (state: RootState) => state.newApplication.status;
export const selectSaveApplicationData = (state: RootState) => state.newApplication.data;

export default newApplicationSlice.reducer;
