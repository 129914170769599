import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import logo from '../assets/images/mad_logo_bw.png';

interface Props {
  loading?: string;
  override?: boolean;
}

const useStyles = makeStyles({
  overlay: {
    background: 'rgba(0, 0, 0, 0.6)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 99999,
    padding: '1em',
  },
  loaderContainer: {
    position: 'absolute',
    padding: '20px 70px',
    borderRadius: 10,
    background: '#eee',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    marginLeft: 3,
    transform: 'translate(-50%, -50%)',
  },
});

const Loader: FC<Props> = ({ loading, override }) => {
  const classes = useStyles();
  return (
    <div>
      {override || loading?.toLowerCase().includes('loading') ? (
        <div className={classes.overlay}>
          <div className={classes.loaderContainer}>
            <img width={200} height={90} src={logo} />
            <Box mt="10px" mb="20px" width={180}>
              <LinearProgress color="inherit" />
            </Box>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Loader;
