import { Box, Grid, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import logo from '../../assets/images/mad_logo.png';
import { useStyles } from './RegisterSupplierStyles';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import { RegisterForm } from '../register/Register';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  postRegisterProfessional,
  selectData,
  selectLoading,
} from '../../service/register/RegisterProfessionalSlice';
import Toast from '../../component/Toast';
import { LoadingButton } from '@mui/lab';
import { formatPhone, ValidateUrls } from '../../helper/AppHelper';
import createDecorator from 'final-form-focus';
import { colors } from '../../theme/Theme';

interface RegisterSupplierForm {
  role: string | null | undefined;
  company: string | null | undefined;
  linkedIn?: string | null | undefined;
  instagram?: string | null | undefined;
}

const focusOnErrors: any = createDecorator();

const RegisterSupplier: FC<{}> = () => {
  const styles = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectLoading);
  const response = useAppSelector(selectData);

  const onSubmit = async (values: RegisterSupplierForm) => {
    const { email, password, title, firstName, lastName, phone, newUser } =
      location.state as RegisterForm;
    const { role, company, linkedIn, instagram } = values;
    const data = {
      email,
      ...(!newUser && { password }),
      role: 'Supplier_Rep',
      phoneNumber: formatPhone(phone!),
      name: {
        firstName,
        lastName,
        title,
      },
      socials: {
        linkedIn,
        instagram,
      },
      workplaceInfo: {
        role,
        organisation: company,
      },
      contactInfo: {
        phone: formatPhone(phone!),
        email,
      },
    };
    const response = await dispatch(postRegisterProfessional(data));
    if (response.type.includes('fulfilled')) {
      setOpen(true);
      if (newUser) {
        navigate('/login', { state: { newUser: true } });
      } else {
        navigate('/verify', { state: email });
      }
    }
  };
  const validate = (values: RegisterSupplierForm) => {
    const { role, company, linkedIn, instagram } = values;
    const errors: RegisterSupplierForm = {
      role: role
        ? undefined
        : 'Please enter your role, eg. Nurse, Carer, Doctor, Child support worker.',
      company: company ? undefined : 'Please enter your company name.',
      linkedIn: linkedIn
        ? ValidateUrls(linkedIn)
          ? undefined
          : 'Please enter a valid url'
        : undefined,
      instagram: instagram
        ? ValidateUrls(instagram)
          ? undefined
          : 'Please enter a valid url'
        : undefined,
    };
    return errors;
  };
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      borderLeft={`4px solid ${colors.pageBorder}`}
    >
      <img
        title="Go to Login"
        onClick={() => navigate('/login')}
        width={100}
        height={44}
        src={logo}
        style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
      />
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid item xs={11} sm={6} md={4} lg={3}>
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: { xs: '100%', sm: 420 },
              px: { xs: 1, sm: 5 },
              py: 4,
            }}
          >
            <Typography className={styles.register} variant="h3">
              Register
            </Typography>
            <Typography variant="subtitle2" className={styles.label}>
              Tell us about yourself.
            </Typography>
            <Form
              onSubmit={onSubmit}
              decorators={[focusOnErrors]}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="role"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Role" />
                    )}
                  />
                  <Field
                    name="company"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Supplier company" />
                    )}
                  />
                  <Typography variant="subtitle2" className={styles.social}>
                    Social
                  </Typography>
                  <Field
                    name="linkedIn"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="LinkedIn (optional)" />
                    )}
                  />
                  <Field
                    name="instagram"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Instagram (optional)" />
                    )}
                  />
                  <LoadingButton
                    loading={loading === 'loading'}
                    type="submit"
                    variant="contained"
                    className={styles.submitBtn}
                    fullWidth
                    color="primary"
                  >
                    Create Account
                  </LoadingButton>
                </form>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        successMsg={'Successfully registered!. Please verify your email to get started.'}
      />
    </Box>
  );
};

export default RegisterSupplier;
