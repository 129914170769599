import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useStyles } from './SettingsStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabPanel } from '../../component/TabPanel';
import CustomSwitch from '../../component/CustomSwitch';
import { colors } from '../../theme/Theme';
import { LoadingButton } from '@mui/lab';
import { Field, Form } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import {
  GetInititals,
  ValidateFiles,
  ValidatePassword,
  ValidatePhone,
  ValidateUrls,
} from '../../helper/AppHelper';
import { PasswordObj, ProfileObj } from '../../service/profile/ProfileModels';
import {
  selectUpdatePasswordData,
  selectUpdatePasswordLoading,
  selectUpdateProfileData,
  selectUpdateProfileLoading,
  updatePassword,
  updateProfile,
  getProfile,
  updatePicture,
  selectUpdatePictureLoading,
  selectUpdatePictureData,
  selectGetProfilePictureData,
  getProfileImageLink,
} from '../../service/profile/ProfileSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Toast from '../../component/Toast';
import { postFileUpload, selectLoading } from '../../service/upload/FileUploadSlice';

export interface BasicInfoForm {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  phone: string | null | undefined;
  email?: string | null | undefined;
}

export interface OtherInfoForm {
  role: string | null | undefined;
  ahpra: string | null | undefined;
  org: string | null | undefined;
  dept: string | null | undefined;
  linkedIn: string | null | undefined;
  instagram: string | null | undefined;
}

export interface PasswordForm {
  currentPassword?: string | null | undefined;
  password?: string | null | undefined;
  confirmPassword?: string | null | undefined;
}

const Settings: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const styles = useStyles();
  const loading = useAppSelector(selectUpdateProfileLoading);
  const data = useAppSelector(selectUpdateProfileData);
  const passwordLoading = useAppSelector(selectUpdatePasswordLoading);
  const profilePictureLoading = useAppSelector(selectUpdatePictureLoading);
  const fileUploadLoading = useAppSelector(selectLoading);
  const passwordData = useAppSelector(selectUpdatePasswordData);
  const profilePictureData = useAppSelector(selectUpdatePictureData);
  const profile = JSON.parse(localStorage.getItem('profile')!);
  const {
    name: { firstName, lastName, fullName },
    email,
    contactInfo: { phone },
    workplaceInfo: { role, ahpra, department, organisation },
    socials: { linkedIn, instagram },
    settings: { receiveEmails },
    picture,
  } = profile || {
    name: {},
    contactInfo: {},
    workplaceInfo: {},
    socials: {},
    settings: {},
    picture: {},
  };
  const id = picture ? picture.id : null;
  const [receiveEmail, setReceiveEmail] = useState<boolean>(receiveEmails);
  const [open, setOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<number>(0);
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [openPicture, setOpenPicture] = useState<boolean>(false);
  const profilePic = localStorage.getItem('profilePictureLink');
  const [imageLink, setImageLink] = useState<string | null>(profilePic);
  const [imagePath, setImagePath] = useState<string | null>(id);
  const dispatch = useAppDispatch();

  const currentValues: ProfileObj = {
    firstName,
    lastName,
    email,
    phoneNumber: phone,
    role,
    ahpra,
    department,
    organisation,
    linkedIn,
    instagram,
    receiveEmails,
  };
  const [changes, setChanges] = useState({});
  const location = useLocation();
  const setImage = location.state ? (location.state as { setImage: Function }).setImage : null;

  const imageData = useAppSelector(selectGetProfilePictureData);
  const imageURL = imageData?.message;
  if (imageURL && !profilePic && profile) {
    localStorage.setItem('profilePictureLink', imageURL);
  }

  const getImageLink = async () => {
    const response = await dispatch(getProfileImageLink({ path: imagePath! }));
    if (response.type.includes('fulfilled')) {
      setImageLink(response.payload.message);
      if (setImage) {
        setImage(response.payload.message);
      }
    }
  };

  useEffect(() => {
    if (imagePath) {
      getImageLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePath]);

  const getProfileData = async () => {
    const response = await dispatch(getProfile());
    if (response.type.includes('fulfilled')) {
      localStorage.setItem('profile', JSON.stringify(response.payload));
    }
  };

  const postUpdates = async (changes: ProfileObj) => {
    const response = await dispatch(updateProfile(changes));
    setOpen(true);
    if (response.type.includes('fulfilled')) {
      getProfileData();
      setUpdate(update + 1);
    }
  };

  const onSubmit = () => {
    postUpdates(changes);
  };

  const handleChange = (key: keyof ProfileObj, value: string | boolean) => {
    if (currentValues[key] !== value || key === 'receiveEmails') {
      const temp = { ...changes, [key]: value };
      setChanges(temp);
      if (key === 'receiveEmails') {
        postUpdates(temp);
      }
    } else {
      const temp: ProfileObj = { ...changes };
      delete temp[key];
      setChanges(temp);
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setActiveTab(tabIndex);
    setChanges({});
  };

  const onSubmitPassword = async (form: PasswordForm) => {
    const data: PasswordObj = {
      password: form.currentPassword!,
      newPassword: form.password!,
    };
    await dispatch(updatePassword(data));
    setOpenPassword(true);
  };

  const validateBasicInfo = (values: BasicInfoForm) => {
    const { firstName, lastName, phone } = values;
    const errors: any = {
      firstName: firstName ? undefined : 'Please enter your first name',
      lastName: lastName ? undefined : 'Please enter your last name',
      phone: ValidatePhone(phone) ? undefined : 'Please enter a valid phone no',
    };
    return errors;
  };

  const validateOtherInfo = (values: OtherInfoForm) => {
    const { role, ahpra, dept, org, linkedIn, instagram } = values;
    const errors: any = {
      role: role ? undefined : 'Please enter your role',
      ahpra: ahpra ? undefined : 'Please enter your ahpra',
      dept: dept ? undefined : 'Please enter a valid phone no',
      org: org ? undefined : 'Please enter a valid phone no',
      linkedIn: linkedIn
        ? ValidateUrls(linkedIn)
          ? undefined
          : 'Please enter a valid url'
        : undefined,
      instagram: instagram
        ? ValidateUrls(instagram)
          ? undefined
          : 'Please enter a valid url'
        : undefined,
    };
    return errors;
  };

  const validatePassword = (values: PasswordForm) => {
    const { password, confirmPassword, currentPassword } = values;
    const errors: any = {
      currentPassword: currentPassword ? undefined : 'Please enter your current password',
      password: ValidatePassword(password),
      confirmPassword:
        confirmPassword && password === confirmPassword ? undefined : 'Passwords don’t match',
    };
    return errors;
  };

  const getInitialBasicInfo = (): BasicInfoForm => {
    return {
      firstName,
      lastName,
      email,
      phone: phone.slice(3),
    };
  };

  const getInitialOtherInfo = (): OtherInfoForm => {
    return {
      role,
      ahpra,
      dept: department,
      org: organisation,
      linkedIn,
      instagram,
    };
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const files = e!.target!.files!;
    const formData: any = new FormData();
    // eslint-disable-next-line
    formData.append('File', files[0]);
    // eslint-disable-next-line
    formData.append('Name', files[0].name);
    const response = await dispatch(postFileUpload(formData));
    if (response.type.includes('fulfilled')) {
      setImagePath(response.payload.path);
      const res = await dispatch(updatePicture({ id: response.payload.path, name: files[0].name }));
      setOpenPicture(true);
      if (res.type.includes('fulfilled')) {
        getProfileData();
      }
    }
  };

  const validateFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validator = ValidateFiles(e.target.files!);
    if (!validator[0]) {
      return false;
    }
    return true;
  };

  return (
    <Box display="flex">
      <Box
        sx={{ pl: { xs: 1.5, sm: 12 }, pb: 6, pr: { xs: 2, sm: 0 } }}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box pt={1.5}>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }} container>
            <Box display="flex">
              <Button onClick={() => navigate(-1)} className={styles.backBtn} variant="text">
                <ArrowBackOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
              </Button>
            </Box>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'left', p: 0.5, mb: 0 }}>
              Profile and Settings
            </Typography>
            <Box width="100%">
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label={'BASIC INFO'} />
                <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label="OTHER INFO" />
                <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label={'PASSWORD'} />
                <Tab sx={{ fontFamily: 'Roboto', fontWeight: 500 }} label="NOTIFICATIONS" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Box mb={1}>
                  <Typography sx={{ fontWeight: 'bold', textAlign: 'left', p: 0.5, fontSize: 16 }}>
                    Profile photo
                  </Typography>
                  <Box display="flex" py={1.5}>
                    <Avatar
                      src={imageURL || imageLink!}
                      sx={{ width: 80, height: 80, bgcolor: colors.grey800, fontWeight: 'bold' }}
                    >
                      {GetInititals(fullName)}
                    </Avatar>
                    <Box ml={2} textAlign="left">
                      <Typography sx={{ p: 0, fontSize: 14, mb: 1 }}>Upload your photo</Typography>
                      <label
                        style={{ width: '100%', cursor: 'pointer' }}
                        htmlFor={`contained-button-file`}
                      >
                        <input
                          onChange={(e) => {
                            const valid = validateFile(e);
                            if (!valid) return;
                            handleFileUpload(e);
                          }}
                          style={{ display: 'none' }}
                          id={`contained-button-file`}
                          type="file"
                        />
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pt="2px"
                        >
                          <LoadingButton
                            loading={
                              profilePictureLoading === 'loading' ||
                              fileUploadLoading.includes('loading')
                            }
                            variant="contained"
                            disableElevation
                            sx={{ width: 72, height: 24, fontSize: 12, ml: 0 }}
                            className={styles.submitBtn}
                            color="primary"
                            onClick={() =>
                              document.getElementById('contained-button-file')!.click()
                            }
                          >
                            {'UPLOAD'}
                          </LoadingButton>
                        </Box>
                      </label>
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="480px">
                  <Typography
                    sx={{ fontWeight: 'bold', textAlign: 'left', p: 0.5, fontSize: 16, mb: 2 }}
                  >
                    Basic Information
                  </Typography>
                  <Box>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateBasicInfo}
                      initialValues={getInitialBasicInfo()}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Field
                            name="firstName"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('firstName', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="First name"
                              />
                            )}
                          />
                          <Field
                            name="lastName"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('lastName', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="Last name"
                              />
                            )}
                          />
                          <Field
                            name="email"
                            render={({ input, meta }) => (
                              <CustomInput
                                disabled
                                input={input}
                                meta={meta}
                                label="Email address"
                              />
                            )}
                          />
                          <Field
                            name="phone"
                            render={({ input, meta }) => (
                              <CustomInput
                                startAdornment="phone"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('phoneNumber', `+61${e.target.value}`)
                                }
                                input={input}
                                meta={meta}
                                label="Phone number"
                              />
                            )}
                          />
                          <LoadingButton
                            loading={loading === 'loading'}
                            variant="contained"
                            type="submit"
                            className={styles.submitBtn}
                            color="primary"
                            sx={{ width: 100, float: 'left' }}
                          >
                            Update
                          </LoadingButton>
                        </form>
                      )}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Box maxWidth="480px">
                  <Typography
                    sx={{ fontWeight: 'bold', textAlign: 'left', p: 0.5, fontSize: 16, mb: 2 }}
                  >
                    About you
                  </Typography>
                  <Box>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateOtherInfo}
                      initialValues={getInitialOtherInfo()}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Field
                            name="role"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('role', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="Role"
                              />
                            )}
                          />
                          <Field
                            name="ahpra"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('ahpra', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="AHPRA number"
                              />
                            )}
                          />
                          <Field
                            name="org"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('organisation', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="Organisation"
                              />
                            )}
                          />
                          <Field
                            name="dept"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('department', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="Department"
                              />
                            )}
                          />
                          <Field
                            name="linkedIn"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('linkedIn', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="Linkedin"
                              />
                            )}
                          />
                          <Field
                            name="instagram"
                            render={({ input, meta }) => (
                              <CustomInput
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChange('instagram', e.target.value)
                                }
                                input={input}
                                meta={meta}
                                label="Instagram"
                              />
                            )}
                          />
                          <LoadingButton
                            loading={loading === 'loading'}
                            variant="contained"
                            type="submit"
                            className={styles.submitBtn}
                            color="primary"
                            sx={{ width: 100, float: 'left' }}
                          >
                            Update
                          </LoadingButton>
                        </form>
                      )}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Box maxWidth="480px">
                  <Typography
                    sx={{ fontWeight: 'bold', textAlign: 'left', p: 0.5, fontSize: 16, mb: 2 }}
                  >
                    Change Password
                  </Typography>
                  <Box>
                    <Form
                      onSubmit={onSubmitPassword}
                      validate={validatePassword}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Field
                            name="currentPassword"
                            render={({ input, meta }) => (
                              <CustomInput
                                input={input}
                                meta={meta}
                                type="password"
                                label="Verify current password"
                              />
                            )}
                          />
                          <Field
                            name="password"
                            type="password"
                            render={({ input, meta }) => (
                              <CustomInput
                                type="password"
                                input={input}
                                meta={meta}
                                label="New password"
                              />
                            )}
                          />
                          <Box
                            textAlign="left"
                            color={colors.activeFilter}
                            fontSize={12}
                            display="flex"
                            mt={-2.5}
                            height={80}
                          >
                            <ul>
                              <li>One lowercase character</li>
                              <li>One uppercase character</li>
                              <li>One number</li>
                            </ul>
                            <ul>
                              <li>One special character</li>
                              <li>8 charactres minimum</li>
                            </ul>
                          </Box>
                          <Field
                            name="confirmPassword"
                            type="password"
                            render={({ input, meta }) => (
                              <CustomInput
                                type="password"
                                input={input}
                                meta={meta}
                                label="Confirm new password"
                              />
                            )}
                          />
                          <LoadingButton
                            loading={passwordLoading === 'loading'}
                            variant="contained"
                            type="submit"
                            sx={{ width: 100, float: 'left' }}
                            className={styles.submitBtn}
                            color="primary"
                          >
                            Update
                          </LoadingButton>
                        </form>
                      )}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <Box>
                  <Typography sx={{ fontWeight: 'bold', textAlign: 'left', p: 0.5, fontSize: 16 }}>
                    Personal Settings
                  </Typography>
                  <Box>
                    <Stack sx={{ pl: 0.6, pr: 3, height: 25, my: 0.7 }} direction="row">
                      <Box width="440px" textAlign="left">
                        <Typography
                          sx={{
                            fontSize: 16,
                            textAlign: 'left',
                            color: colors.grey700,
                          }}
                        >
                          {'Email Notifications'}
                        </Typography>
                      </Box>
                      <Stack width={32} pt={0.5} spacing={3} direction="row">
                        {loading === 'loading' ? (
                          <CircularProgress sx={{ mb: '-2px' }} size="15px" />
                        ) : (
                          <CustomSwitch
                            onChange={(e) => {
                              setReceiveEmail(e.target.checked);
                              handleChange('receiveEmails', e.target.checked);
                            }}
                            checked={receiveEmail}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Toast
        loading={loading}
        response={data}
        open={open}
        successMsg="Changes saved."
        setOpen={setOpen}
        errorMsg="Something went wrong, please try again later."
        autoHideDuration={3000}
      />
      <Toast
        loading={passwordLoading}
        response={passwordData}
        open={openPassword}
        successMsg="Changes saved."
        setOpen={setOpenPassword}
        autoHideDuration={3000}
      />
      <Toast
        loading={profilePictureLoading}
        response={profilePictureData}
        open={openPicture}
        successMsg="Changes saved."
        setOpen={setOpenPicture}
        autoHideDuration={3000}
      />
      <Box
        sx={{ display: { xs: 'none', md: 'block' } }}
        minHeight={`calc(100vh - 65px)`}
        width={168}
        bgcolor="#FFD666"
      ></Box>
    </Box>
  );
};

export default Settings;
