import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  register: {
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'left',
  },
  submitBtn: {
    color: 'white',
  },
  label: {
    marginBottom: 12,
  },
  select: {
    height: 40,
  },
  social: {
    marginBottom: 12,
  },
  logo: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
});
