import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import logo from '../../assets/images/mad_logo.png';
import { useStyles } from './VerificationStyles';
import { Form, Field } from 'react-final-form';
import Label from '../../component/Label';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { colors } from '../../theme/Theme';
import { CensorEmail } from '../../helper/AppHelper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectData, selectLoading, postVerify } from '../../service/verify/VerifySlice';
import { LoadingButton } from '@mui/lab';
import Toast from '../../component/Toast';

interface VerificationForm {
  code: string | null | undefined;
}

const Verification: FC<{}> = () => {
  const styles = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState('');

  const loading = useAppSelector(selectLoading);
  const response = useAppSelector(selectData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values: VerificationForm) => {
    const { code } = values;
    setOpen(true);
    const response = await dispatch(
      postVerify({ confirmationCode: code, email: location.state as string }),
    );
    if (response.type.includes('fulfilled')) {
      setError('');
      setTimeout(function () {
        navigate('/login');
      }, 2000);
    } else if (response.type.includes('rejected')) {
      if (response.payload.message.toLowerCase().includes('invalid')) {
        setError(response.payload.message);
      }
    }
  };
  const validate = (values: VerificationForm) => {
    const errors: VerificationForm = {
      code: values.code && values.code.length === 6 ? undefined : 'Enter a valid code',
    };
    return errors;
  };

  const censoredEmail = CensorEmail(location.state as string);

  return (
    <Box
      borderLeft={`4px solid ${colors.pageBorder}`}
      height="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <img
        title="Go to Login"
        onClick={() => navigate('/login')}
        width={100}
        height={44}
        src={logo}
        style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
      />
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid item xs={11} sm={7} md={5} lg={4}>
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: '95%',
              pl: { xs: 2, sm: 5 },
              pr: { xs: 2, sm: 5 },
              py: 4,
            }}
          >
            <Typography className={styles.login} variant="h3">
              Almost there!
            </Typography>
            <Typography variant="h6" className={styles.label}>
              We’ve sent you a verification code to {censoredEmail}. Please check your inbox and
              enter the code below to get started.
            </Typography>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="code"
                    render={({ input, meta }) => (
                      <Box mt={1} mb={3} display="flex">
                        <Box>
                          <Label
                            style={{ marginLeft: 3 }}
                            error={meta.touched && meta.error}
                            text="Enter your 6 digit code"
                          />
                          <Box display="flex" justifyContent="center" mt={1}>
                            <OtpInput
                              value={input.value}
                              onChange={input.onChange}
                              hasErrored={(meta.touched && meta.error) || !!error}
                              numInputs={6}
                              errorStyle={{
                                border: `1px solid ${colors.errorText}`,
                                background: colors.errorBg,
                              }}
                              isInputNum
                              inputStyle={{
                                width: 40,
                                height: 40,
                                margin: 6,
                                borderRadius: 4,
                                border: `1px solid ${colors.grey600}`,
                                fontSize: 14,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  />
                  <LoadingButton
                    loading={loading === 'loading'}
                    variant="contained"
                    type="submit"
                    fullWidth
                    className={styles.submitBtn}
                    color="primary"
                    disabled={!!errors?.code}
                  >
                    Verify
                  </LoadingButton>
                </form>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        successMsg="Email successfully verified. Please login."
      />
    </Box>
  );
};

export default Verification;
