import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/Theme';

export const useStyles = makeStyles({
  login: {
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'left',
  },
  submitBtn: {
    color: 'white',
    marginTop: 24,
  },
  createBtn: {
    color: colors.grey600,
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 5,
  },
  forgotPasswordBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '0px 2px',
    fontSize: 14,
    height: 18,
    float: 'left',
    color: colors.grey600,
    textDecoration: 'underline',
  },
  googleBtn: {
    color: 'rgba(0, 0, 0, 0.54)',
    background: colors.white,
    borderColor: '#A8B0B9',
    '&:hover': {
      backgroundColor: '#eee',
      borderColor: '#A8B0B9',
    },
  },
});
