import { createTheme, darken, lighten } from '@mui/material/styles';

export const colors = {
  primary: '#007DDC',
  primaryHeader: '#1F3D99',
  pageBorder: '#007DDC',
  border: '#ddd',
  sectionBorder: '#EAECEE',
  secondary: '#fff',
  secondaryBorder: '#1976D2',
  white: '#fff',
  grey800: '#0C1116',
  grey700: '#373F47',
  grey600: '#4F5B67',
  background: '#fafafa',
  primaryDisabled: '#D6E0FF',
  primaryHover: darken('#2952CC', 0.2),
  errorText: '#D32F2F',
  errorBg: '#FEEEEF',
  black: '#000000',
  errorBtn: '#C14649',
  blueBorder: '#1F3D99',
  label: 'rgb(0,0,0, 0.87)',
  active: lighten('#D6E0FF', 0.6),
  pending: '#F57F17',
  activeFilter: '#007DFF',
  approved: '#2E7D32',
  awaitingApproval: '#0288D1',
  quoteNotFinal: '#ED6C02',
  rejected: '#D32F2F',
  sent: '#a0a0a0',
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.background,
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
    },
    h2: {
      marginBottom: 6,
      textAlign: 'left',
      fontSize: 24,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      color: colors.grey600,
    },
    h6: {
      fontSize: 14,
      color: colors.grey700,
      textAlign: 'left',
    },
    h5: {
      fontSize: 16,
      color: colors.grey600,
      textAlign: 'left',
    },
    subtitle2: {
      fontSize: 14,
      color: 'black',
      textAlign: 'left',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 15,
      color: colors.primaryHeader,
      textAlign: 'left',
      marginBottom: 8,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 48,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: 'left',
          display: 'flex',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: '#D6E0FF',
            borderRight: `4px solid ${colors.blueBorder}`,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'lineHeight': 1.75,
          'marginTop': 10,
          'padding': '10px',
          'height': '40px',
          'borderRadius': '4px',
          'fontSize': '14px',
          'fontWeight': '700',
          '&$disabled': {
            backgroundColor: colors.primaryDisabled,
          },
          '&:hover': {
            backgroundColor: colors.primaryHover,
          },
        },
        containedSecondary: {
          backgroundColor: colors.secondary,
          color: colors.secondaryBorder,
          border: `1px solid ${colors.secondaryBorder}`,
          '&:hover': {
            backgroundColor: colors.primaryDisabled,
          },
        },
        text: {
          textTransform: 'none',
          '&$disabled': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: colors.primaryHover,
            textDecoration: 'underline',
          },
        },
        outlined: {
          textTransform: 'none',
          '&:hover': {
            backgroundColor: colors.primaryDisabled,
            color: colors.primaryHover,
          },
        },
      },
    },
  },
});

export default theme;
