import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Toast from '../../component/Toast';
import {
  selectBeneficiaryData,
  selectSaveBeneficiaryLoading,
} from '../../service/application/BeneficiarySlice';
import {
  selectSaveApplicationData,
  selectSaveApplicationLoading,
} from '../../service/application/NewApplicationSlice';
import {
  selectSupplierData,
  selectSaveSupplierLoading,
} from '../../service/application/SupplierSlice';
import { colors } from '../../theme/Theme';
import Beneficiary from '../beneficiary/BeneficiaryDetails';
import Summary from '../summary/Summary';
import Supplier, { SupplierItem } from '../supplier/Supplier';
import {
  getApplicationById,
  getApplicationByIdSlice,
  selectGetApplicationByIdData,
} from '../../service/application/ApplicationListSlice';
import {
  ApplicationResponse,
  ItemDetails,
  OrderDetails,
  Document,
  Status,
} from '../../service/application/ApplicationModels';
import ErrorPage from '../layout/ErrorPage';

export interface NamePathMapper {
  [key: string]: string;
}

const Application: FC<{}> = () => {
  const [supplierDetails, setSupplierDetails] = useState<any>([]);
  const [step, setStep] = useState<number>(1);
  const [supplierFileIds, setSupplierFileIds] = useState<string[][]>([]);
  const [beneficiaryFileIds, setBeneficiaryFileIds] = useState<string[]>([]);
  const [fileNamePathMapper, setFileNamePathMapper] = useState<NamePathMapper>({});
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<any>({});
  const [supplierItems, setSupplierItems] = useState<SupplierItem[][]>([]);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [openBeneficiary, setOpenBeneficiary] = useState(false);
  const [openApplication, setOpenApplication] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [applicationId, setApplicationId] = useState<string | undefined>(undefined);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pathname } = location;
  const supplierResponse = useAppSelector(selectSupplierData);
  const beneficiaryResponse = useAppSelector(selectBeneficiaryData);
  const applicationResponse = useAppSelector(selectSaveApplicationData);
  const saveSupplierLoading = useAppSelector(selectSaveSupplierLoading);
  const saveBeneficiaryLoading = useAppSelector(selectSaveBeneficiaryLoading);
  const saveApplicationLoading = useAppSelector(selectSaveApplicationLoading);
  const appIdParams = location.pathname.split('/');
  const appIdParam = appIdParams[appIdParams.length - 1];

  const appData = useAppSelector(selectGetApplicationByIdData);
  const status = appData ? appData.applicationStatus : '';

  const getFiles = (docs: Document[]) => {
    const dt = new DataTransfer();
    docs.forEach((d: Document) => {
      const file = new File([], d.name, { type: d.id });
      dt.items.add(file);
    });
    return dt.files;
  };

  const setApplicationState = (data: ApplicationResponse) => {
    const supplierObj = data.orders.map((o: OrderDetails) => ({
      final: o.invoiceIsFinal ? 'Yes' : 'No',
      supplier: o.companyName,
      supplierId: o.supplierId,
      item: o.items.map((i: ItemDetails) => i.description),
      itemId: o.items.map((i: ItemDetails) => i.itemId),
      filePaths: o.documents.map((i: Document) => i.id),
      file: getFiles(o.documents),
      cost: o.cost.actualCost,
      costType: o.cost.actualCost ? 'Actual' : 'Estimate',
      highest: o.cost.highestCost,
      lowest: o.cost.lowestCost,
    }));
    setSupplierDetails(supplierObj);
    if (data.beneficiaryInfo) {
      const {
        beneficiaryName: { firstName, lastName, preferredName },
        dateOfBirth,
        documents,
        englishSpeaking,
        parentEmail,
        parentName: { firstName: parentFirstName, lastName: parentLastName },
        phoneNo,
      } = data.beneficiaryInfo;
      const beneficiaryObj = {
        dob: dateOfBirth,
        engSpeaking: englishSpeaking ? 'Yes' : 'No',
        fileIds: documents.map((i: Document) => i.id),
        file: getFiles(documents),
        firstName,
        lastName,
        parentEmail,
        parentFirstName,
        parentLastName,
        parentPhone: phoneNo.slice(3),
        preferredName,
      };
      setBeneficiaryDetails(beneficiaryObj);
    }
  };

  const getData = () => {
    const appId = location.state
      ? (location.state as { applicationId: string }).applicationId
      : null;
    const fetchData = async (applicationId: string) => {
      const response = await dispatch(getApplicationById({ applicationId }));
      if (response.type.includes('fulfilled')) {
        const application: ApplicationResponse = response.payload;

        setApplicationState(application);
        setApplicationId(application.applicationId);

        const supplierFileIds = application.orders.map((order: OrderDetails) => [
          order.documents[0].id,
        ]);

        const mapper: NamePathMapper = {};
        application.orders.forEach((order: OrderDetails) => {
          mapper[order.documents[0].name] = order.documents[0].id;
        });

        if (application.beneficiaryInfo) {
          application.beneficiaryInfo.documents.forEach((d: Document) => {
            mapper[d.name] = d.id;
          });
        }

        setFileNamePathMapper(mapper);
        setSupplierFileIds(supplierFileIds);
        if (application.beneficiaryInfo) {
          const beneficiaryFileIds = application.beneficiaryInfo.documents.map(
            (d: Document) => d.id,
          );
          setBeneficiaryFileIds(beneficiaryFileIds);
        }
      }
    };
    fetchData(applicationId || appId! || appIdParam).catch(console.error);
  };

  useEffect(() => {
    const appId = location.state
      ? (location.state as { applicationId: string }).applicationId
      : null;
    if (!applicationId && appId) {
      getData();
    }
    return () => {
      dispatch(getApplicationByIdSlice.actions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getComponent = () => {
    if (pathname.includes('supplier')) {
      if (step !== 1) setStep(1);
      return (
        <Supplier
          supplierItems={supplierItems}
          setSupplierItems={setSupplierItems}
          supplierDetails={supplierDetails}
          setSupplierDetails={setSupplierDetails}
          fileNamePathMapper={fileNamePathMapper}
          setFileNamePathMapper={setFileNamePathMapper}
          fileIds={supplierFileIds}
          setFileIds={setSupplierFileIds}
          applicationId={applicationId}
          setApplicationId={setApplicationId}
          setOpenSupplier={setOpenSupplier}
          getData={getData}
        />
      );
    } else if (pathname.includes('beneficiary')) {
      if (step !== 2) setStep(2);
      return (
        <Beneficiary
          supplierDetails={supplierDetails}
          beneficiaryDetails={beneficiaryDetails}
          setBeneficiaryDetails={setBeneficiaryDetails}
          fileNamePathMapper={fileNamePathMapper}
          setFileNamePathMapper={setFileNamePathMapper}
          fileIds={beneficiaryFileIds}
          setFileIds={setBeneficiaryFileIds}
          setOpenBeneficiary={setOpenBeneficiary}
          getData={getData}
          applicationId={applicationId}
        />
      );
    } else if (pathname.includes('summary')) {
      if (step !== 3) setStep(3);
      return (
        <Summary
          supplierDetails={supplierDetails}
          beneficiaryDetails={beneficiaryDetails}
          setOpenApplication={setOpenApplication}
          setModalOpen={setModalOpen}
          setModalType={setModalType}
          getData={getData}
          fileNamePathMapper={fileNamePathMapper}
        />
      );
    } else {
      return <ErrorPage hideLeftLine />;
    }
  };
  return (
    <Box>
      {status === Status.Draft && (
        <Box
          sx={{
            position: 'sticky',
            top: { xs: 56, sm: 65 },
            width: '100%',
            zIndex: 999,
            height: 33,
            background: colors.white,
            borderBottom: `1px solid ${colors.border}`,
            boxShadow: '0 14px 14px -14px rgb(0 0 0 / 20%)',
          }}
        >
          <Typography
            sx={{
              pl: { xs: 2, sm: 12 },
              display: 'flex',
              fontSize: 12,
              alignItems: 'center',
              height: '100%',
              color: colors.primaryHeader,
              fontWeight: 600,
            }}
          >
            Step {step} of 3
          </Typography>
        </Box>
      )}
      <Box display="flex">
        <Box
          sx={{ pl: { xs: 1.5, sm: 12 }, pb: 6, pr: { xs: 2, sm: 0 } }}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {getComponent()}
        </Box>
        <Box
          sx={{ display: { xs: 'none', md: 'block' } }}
          minHeight={`calc(100vh - 65px)`}
          width={168}
          bgcolor="#FFD666"
        ></Box>
      </Box>
      <Dialog onClose={handleCloseModal} open={modalOpen}>
        <DialogContent>
          {modalType === 'Supplier' ? (
            <Supplier
              supplierItems={supplierItems}
              setSupplierItems={setSupplierItems}
              supplierDetails={supplierDetails}
              setSupplierDetails={setSupplierDetails}
              fileNamePathMapper={fileNamePathMapper}
              setFileNamePathMapper={setFileNamePathMapper}
              fileIds={supplierFileIds}
              setFileIds={setSupplierFileIds}
              applicationId={applicationId}
              setApplicationId={setApplicationId}
              setOpenSupplier={setOpenSupplier}
              modalOpen
              setModalOpen={setModalOpen}
              setModalType={setModalType}
              getData={getData}
            />
          ) : (
            <Beneficiary
              supplierDetails={supplierDetails}
              beneficiaryDetails={beneficiaryDetails}
              setBeneficiaryDetails={setBeneficiaryDetails}
              fileNamePathMapper={fileNamePathMapper}
              setFileNamePathMapper={setFileNamePathMapper}
              fileIds={beneficiaryFileIds}
              setFileIds={setBeneficiaryFileIds}
              setOpenBeneficiary={setOpenBeneficiary}
              modalOpen
              setModalOpen={setModalOpen}
              setModalType={setModalType}
              getData={getData}
              applicationId={applicationId}
            />
          )}
        </DialogContent>
      </Dialog>
      <Toast
        loading={saveSupplierLoading}
        response={supplierResponse}
        open={openSupplier}
        setOpen={setOpenSupplier}
        successMsg={'Saved.'}
        autoHideDuration={3000}
      />
      <Toast
        loading={saveBeneficiaryLoading}
        response={beneficiaryResponse}
        open={openBeneficiary}
        setOpen={setOpenBeneficiary}
        successMsg={'Saved.'}
        autoHideDuration={3000}
      />
      <Toast
        loading={saveApplicationLoading}
        response={applicationResponse}
        open={openApplication}
        setOpen={setOpenApplication}
        successMsg="Success."
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default Application;
