import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../AxiosSetup';
import {
  ThreadAttachmentDeleteRequest,
  ThreadAttachmentRequest,
  ThreadObj,
} from './ApplicationModels';

export interface ThreadState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: ThreadState = {
  status: 'idle',
  data: null,
};

export const putThread = createAsyncThunk(
  'Put thread',
  async (data: ThreadObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('thread-message/create', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getThread = createAsyncThunk(
  'Get thread',
  async (applicationId: string, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(
        getUrl('thread-message/get', {}),
        { applicationId },
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getAttachments = createAsyncThunk(
  'Get attachments',
  async (applicationId: string, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.get(
        getUrl('thread-document/get', { query: { applicationId } }),
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const postAttachment = createAsyncThunk(
  'Post attachment',
  async (data: ThreadAttachmentRequest, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(
        getUrl('/thread-document/create', {}),
        data,
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const deleteAttachment = createAsyncThunk(
  'Delete attachment',
  async (data: ThreadAttachmentDeleteRequest, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    const { applicationId, documentId } = data;
    try {
      const response = await axios.delete(
        getUrl('/thread-document/delete', { query: { applicationId, documentId } }),
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const PutThreadSlice = createSlice({
  name: 'Put thread',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putThread.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putThread.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(putThread.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const GetThreadSlice = createSlice({
  name: 'Get thread',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getThread.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getThread.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getThread.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const GetAttachmentsSlice = createSlice({
  name: 'Get attachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAttachments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAttachments.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getAttachments.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const PostAttachmentSlice = createSlice({
  name: 'Get attachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postAttachment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postAttachment.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postAttachment.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const DeleteAttachmentSlice = createSlice({
  name: 'Delete attachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteAttachment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAttachment.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(deleteAttachment.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectThreadLoading = (state: RootState) => state.putThread.status;

export const selectGetThreadLoading = (state: RootState) => state.getThread.status;

export const selectGetAttachmentLoading = (state: RootState) => state.getAttachments.status;

export const selectPostAttachmentLoading = (state: RootState) => state.postAttachment.status;

export const selectDeleteAttachmentLoading = (state: RootState) => state.deleteAttachment.status;

export const putThreadReducer = PutThreadSlice.reducer;
export const getThreadReducer = GetThreadSlice.reducer;
export const getAttachmentReducer = GetAttachmentsSlice.reducer;
export const postAttachmentThreadReducer = PostAttachmentSlice.reducer;
export const deleteAttachmentThreadReducer = DeleteAttachmentSlice.reducer;
