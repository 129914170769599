import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ThreadAttachment, ThreadMsg } from '../../service/application/ApplicationModels';
import {
  deleteAttachment,
  postAttachment,
  putThread,
  selectDeleteAttachmentLoading,
  selectGetThreadLoading,
  selectPostAttachmentLoading,
  selectThreadLoading,
} from '../../service/application/ThreadMsgSlice';
import { deleteFile, postFileUpload, selectLoading } from '../../service/upload/FileUploadSlice';
import { colors } from '../../theme/Theme';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import { ValidateFiles } from '../../helper/AppHelper';
import Label from '../Label';
import FileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { styled } from '@mui/styles';
import { useStyles } from './ThreadStyles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getLink } from '../../service/upload/ViewFileSlice';
import DocPreview from '../DocPreview';
import { NamePathMapper } from '../../container/application/Application';

interface Props {
  applicationId: string;
  threads: ThreadMsg[];
  fetchThread: Function;
  fetchThreadAttachments: Function;
  attachments: any;
}

const Input = styled('input')({
  display: 'none',
});

const Thread: FC<Props> = ({
  applicationId,
  threads,
  fetchThread,
  attachments,
  fetchThreadAttachments,
}) => {
  const [message, setMessage] = useState<string>('');
  const [fileErrors, setFileErrors] = useState<string | boolean>(false);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [filePaths, setFilePaths] = useState<string[]>([]);
  const [deletedFilePaths, setDeletedFilePaths] = useState<string[]>([]);
  const [mode, setMode] = useState<string>('View');
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [docLink, setDocLink] = useState<string | undefined>(undefined);
  const [docName, setDocName] = useState<string | undefined>(undefined);
  const [fileNamePathMapper, setFileNamePathMapper] = useState<NamePathMapper | null>(null);

  const dispatch = useAppDispatch();
  const styles = useStyles();

  const getFiles = (docs: ThreadAttachment[]) => {
    const dt = new DataTransfer();
    const mapper: NamePathMapper = {};
    (docs || []).forEach((d: ThreadAttachment) => {
      mapper[d.document.name] = d.document.id;
      const file = new File([], d.document.name, { type: d.document.id });
      dt.items.add(file);
    });
    if (!fileNamePathMapper) {
      setFileNamePathMapper(mapper);
    }
    return dt.files;
  };

  const createThread = async () => {
    const response = await dispatch(putThread({ applicationId, message }));
    if (response.type.includes('fulfilled')) {
      fetchThread();
      setMessage('');
    }
  };

  const getLoading = useAppSelector(selectGetThreadLoading);
  const putLoading = useAppSelector(selectThreadLoading);
  const postAttachmentLoading = useAppSelector(selectPostAttachmentLoading);
  const fileLoading = useAppSelector(selectLoading);
  const deleteThreadAttachmentLoading = useAppSelector(selectDeleteAttachmentLoading);

  const handleTextArea = (e: any) => {
    setMessage(e.target.value);
  };

  const getDays = (date: string) => {
    const diff = moment().diff(moment(date), 'days');
    if (diff === 0) return 'Today';
    if (diff === 1) return `Yesterday`;
    if (diff < 30) return `${diff} days ago`;
    if (diff >= 30) return moment(date).format('Do MMM');
  };

  const profile = JSON.parse(localStorage.getItem('profile')!);
  const loggedInUser = profile ? profile.name.fullName : '';

  const isLoggedIn = (name: string) => {
    return loggedInUser === name;
  };

  const handleFileUploaded = async (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const files = e!.target!.files!;
    const paths = [...filePaths];
    const names = [...fileNames];
    const formData: any = new FormData();
    // eslint-disable-next-line
    formData.append('File', files[0]);
    // eslint-disable-next-line
    formData.append('Name', files[0].name);
    const response = await dispatch(postFileUpload(formData));
    if (response.type.includes('fulfilled')) {
      const filePath = response.payload.path;
      paths.push(filePath);
      names.push(files[0].name);
      const mapper = { ...fileNamePathMapper };
      mapper[files[0].name] = filePath;
      setFileNamePathMapper(mapper);
      setFileNames(names);
      setFilePaths(paths);
    }
  };

  const saveAttachments = async () => {
    for (let i = 0; i < fileNames.length; i++) {
      await dispatch(
        postAttachment({
          applicationId,
          document: {
            name: fileNames[i],
            id: filePaths[i],
          },
        }),
      );
    }
    for (let i = 0; i < deletedFilePaths.length; i++) {
      await dispatch(
        deleteAttachment({
          applicationId,
          documentId: deletedFilePaths[i],
        }),
      );
    }
    setMode('View');
    setFileNames([]);
    setFilePaths([]);
    fetchThreadAttachments();
    setDeletedFilePaths([]);
  };

  const getInitialData = () => {
    return {
      file: getFiles(attachments),
    };
  };

  const validateFile = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const validator = ValidateFiles(e!.target.files!);
    const errors = validator[0] ? false : validator[1] || 'Please upload a supplementing doc';
    setFileErrors(errors);
    if (!validator[0]) {
      return false;
    }
    return true;
  };

  const handlePreview = async (name: string, path: string) => {
    setOpenPreview(true);
    const response = await dispatch(getLink({ path }));
    if (response.type.includes('fulfilled')) {
      setDocName(name);
      setDocLink(response.payload.message);
    }
  };

  const handleRemove = async (index: number, setValue: Function, files: FileList) => {
    const deletedPaths = [...deletedFilePaths];
    const response = await dispatch(deleteFile({ path: fileNamePathMapper![files[index].name] }));
    if (response.type.includes('fulfilled') || response.type.includes('rejected')) {
      deletedPaths.push(fileNamePathMapper![files[index].name]);
      const dt = new DataTransfer();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
      }
      setValue('file', dt.files.length ? dt.files : null);
      setDeletedFilePaths(deletedPaths);
    }
  };

  const getFileNames = (files: FileList, error: boolean, setValue: Function) => {
    const box = [];
    for (let i = 0; i < files.length; i++) {
      box.push(
        <Box
          sx={{ marginTop: { xs: -2, sm: 0 }, marginBottom: { xs: 4, sm: 2 } }}
          key={`file-${files[i].name}`}
          height={18}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex">
            <FileOutlinedIcon color={error ? 'error' : 'primary'} />
            <Typography
              title="View"
              onClick={() => {
                handlePreview(
                  fileNames[i] || files[i].name,
                  fileNamePathMapper![fileNames[i] || files[i].name],
                );
              }}
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                fontSize: 16,
                textOverflow: 'ellipsis',
                maxWidth: 180,
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                height: 24,
                cursor: 'pointer',
                color: `${error ? colors.errorText : colors.grey700}`,
                ':hover': {
                  textDecoration: 'underline',
                  textDecorationColor: colors.primary,
                  color: colors.primary,
                },
              }}
            >
              {files[i].name || 'Uploaded File'}
            </Typography>
          </Box>
          <Box>
            {fileLoading === `loading-${fileNames[i] || files[i].name}` ||
            fileLoading ===
              `deleting-${filePaths[i] || fileNamePathMapper![fileNames[i] || files[i].name]}` ? (
              <CircularProgress size="15px" />
            ) : (
              mode === 'Edit' && (
                <IconButton
                  sx={{ p: '0px 4px 3px 4px', m: 0 }}
                  onClick={() => handleRemove(i, setValue, files)}
                >
                  <DeleteOutlinedIcon color="error" />
                </IconButton>
              )
            )}
          </Box>
        </Box>,
      );
    }
    return box;
  };

  const validate = (value: any) => {
    const { file } = value;
    const fileValidator = ValidateFiles(file)[0] ? undefined : ValidateFiles(file)[1];
    const error = {
      file:
        fileValidator || fileValidator === undefined ? fileValidator : 'Please upload a valid doc',
    };
    return error;
  };

  return (
    <Box display="flex" flexWrap={'wrap'} mt={-2}>
      <Box maxWidth="730px" mt={2} width="100%" minWidth="360px" mr={2} sx={{}}>
        <Box display="flex" position="relative">
          <TextareaAutosize
            value={message}
            onChange={handleTextArea}
            placeholder="Enter a comment"
            style={{
              width: '100%',
              height: 78,
              fontFamily: 'Arial',
              padding: 12,
              borderRadius: 4,
              marginLeft: '8px',
              fontSize: 14,
              borderColor: '#D6DADE',
            }}
          />
          <LoadingButton
            variant="outlined"
            loading={putLoading === 'loading'}
            color="primary"
            onClick={createThread}
            sx={{
              width: 80,
              height: 30,
              position: 'absolute',
              right: 0,
              mx: 4,
              mt: 3,
              color: '#0288D1',
              fontSize: 15,
              fontWeight: 400,
              borderColor: '#0288D1',
            }}
          >
            POST
          </LoadingButton>
        </Box>
        {getLoading === 'loading' && (
          <Box textAlign="center" p={1} color={colors.sent} fontSize="13px">
            Loading...
          </Box>
        )}
        {(!threads || threads.length === 0) && getLoading !== 'loading' && (
          <Box textAlign="center" p={1} color={colors.grey600} fontSize="14px">
            No messages
          </Box>
        )}
        <Box pr={1}>
          {threads.map((msg: ThreadMsg) => (
            <Box>
              {msg.authorName === 'SYSTEM' || msg.authorName === '' ? (
                <Box m={1} p={2} width="100%" textAlign="right">
                  <Typography fontWeight={500} color="#242D35" fontSize={12} fontFamily="Roboto">
                    {getDays(msg.dateTimePosted)}
                  </Typography>
                  <Typography color={colors.grey700} fontSize={14}>
                    {msg.message}
                  </Typography>
                </Box>
              ) : (
                <Box
                  m={1}
                  p={2}
                  width="100%"
                  bgcolor={isLoggedIn(msg.authorName) ? '#EBF0FF' : '#F1F1F1'}
                  textAlign="left"
                >
                  <Typography
                    fontWeight={500}
                    color={isLoggedIn(msg.authorName) ? colors.pageBorder : '#242D35'}
                    fontSize={12}
                    fontFamily="Roboto"
                  >
                    {msg.authorName}
                    {isLoggedIn(msg.authorName) ? ' ( You )' : ''} - {getDays(msg.dateTimePosted)}
                  </Typography>
                  <Typography color={colors.grey700} fontSize={14}>
                    {msg.message}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{ width: { xs: '100%', sm: 280 } }}
        ml={1}
        mt={2}
        minWidth="280px"
        height="100%"
        bgcolor="silver"
        mr={2}
      >
        <Box bgcolor="white">
          <Stack
            sx={{
              position: 'relative',
              background: '#F5F5F5',
              border: '1px solid #D6DADE',
              height: 40,
              padding: '8px 16px',
            }}
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Box textAlign="left">
              <Typography sx={{ fontSize: 16 }}>Attachments</Typography>
            </Box>
          </Stack>
          <Box sx={{ width: '100%', p: 2, pb: 0 }}>
            <Form
              onSubmit={() => void 0}
              validate={validate}
              initialValues={getInitialData()}
              mutators={{
                setValue: (args, state, utils) => {
                  utils.changeValue(state, args[0], () => args[1]);
                },
              }}
              keepDirtyOnReinitialize
              render={({
                form: {
                  mutators: { setValue },
                },
              }) => (
                <form>
                  <Field
                    name="file"
                    type="file"
                    render={({ input, meta }) => (
                      <Box sx={{ marginBottom: { xs: 0, sm: 1 } }}>
                        {input.value && input.value.length ? (
                          <Box sx={{ mt: { xs: 3, sm: 1 }, mb: { xs: 0, sm: 3 } }}>
                            {getFileNames(
                              input.value,
                              (meta.touched && meta.error) || fileErrors,
                              setValue,
                            )}
                          </Box>
                        ) : (
                          <Typography fontSize="14px">No Attachments</Typography>
                        )}
                        {mode === 'Edit' && (
                          <Box mt={2}>
                            <div
                              className={
                                (meta.touched && meta.error) || fileErrors
                                  ? styles.dropBoxError
                                  : styles.dropBox
                              }
                            >
                              <label
                                style={{ width: '100%', cursor: 'pointer' }}
                                htmlFor="contained-button-file"
                              >
                                <Input
                                  onChange={(e: any) => {
                                    const valid = validateFile(e);
                                    if (!valid) return;
                                    handleFileUploaded(e);
                                    input.onChange(
                                      Array.from(e.target.files!).concat(Array.from(input.value)),
                                    );
                                  }}
                                  id="contained-button-file"
                                  type="file"
                                />
                                <Box
                                  width="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  px={1.5}
                                >
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      textOverflow: 'ellipsis',
                                      maxWidth: 160,
                                      whiteSpace: 'nowrap',
                                      overflowX: 'hidden',
                                    }}
                                    color={
                                      (meta.touched && meta.error) || fileErrors
                                        ? colors.errorText
                                        : 'action'
                                    }
                                  >
                                    Upload Documents
                                  </Typography>
                                  <FileUploadIcon
                                    color={
                                      (meta.touched && meta.error) || fileErrors
                                        ? 'error'
                                        : 'action'
                                    }
                                  />
                                </Box>
                              </label>
                            </div>

                            <Label
                              error={(meta.touched && meta.error) || fileErrors}
                              style={
                                (meta.touched && meta.error) || fileErrors
                                  ? { marginTop: -12, marginBottom: 8, marginLeft: 12 }
                                  : {}
                              }
                              text={fileErrors || meta.error}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  />
                </form>
              )}
            />
          </Box>
          <LoadingButton
            sx={{ m: 1, width: '90%' }}
            variant="outlined"
            disabled={
              fileLoading.includes('loading') ||
              fileLoading.includes('deleting') ||
              postAttachmentLoading === 'loading' ||
              deleteThreadAttachmentLoading === 'loading'
            }
            onClick={() => {
              if (mode === 'Edit') {
                saveAttachments();
              } else {
                setMode('Edit');
              }
            }}
          >
            {mode === 'Edit'
              ? filePaths.length || deletedFilePaths.length
                ? 'Save'
                : 'Cancel'
              : 'Edit Attachments'}
          </LoadingButton>
        </Box>
      </Box>
      <DocPreview
        open={openPreview}
        setOpen={setOpenPreview}
        link={docLink}
        name={docName || 'Uploaded File'}
        setDocLink={setDocLink}
        setDocName={setDocName}
      />
    </Box>
  );
};

export default Thread;
