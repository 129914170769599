import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../service/login/LoginSlice';
import registerProfessionalReducer from '../service/register/RegisterProfessionalSlice';
import registerReducer from '../service/register/RegisterSlice';
import verifyReducer from '../service/verify/VerifySlice';
import forgotPasswordReducer from '../service/forgotPassword/ForgotPasswordSlice';
import verifyForgotPasswordReducer from '../service/forgotPassword/VerifySlice';
import newApplicationReducer from '../service/application/NewApplicationSlice';
import applicationListReducer, {
  getAllApplicationsReducer,
  getApplicationByIdReducer,
  getRecentApplicationsReducer,
} from '../service/application/ApplicationListSlice';
import supplierReducer from '../service/supplier/SupplierSlice';
import fileUpload from '../service/upload/FileUploadSlice';
import profile, {
  getPictureReducer,
  updatePasswordReducer,
  updatePictureReducer,
  updateProfileReducer,
} from '../service/profile/ProfileSlice';
import saveSupplierReducer from '../service/application/SupplierSlice';
import saveBeneficiaryReducer from '../service/application/BeneficiarySlice';
import putStatusReducer, { batchApprovalReducer } from '../service/application/StatusSlice';
import {
  deleteAttachmentThreadReducer,
  getAttachmentReducer,
  getThreadReducer,
  postAttachmentThreadReducer,
  putThreadReducer,
} from '../service/application/ThreadMsgSlice';
import getDeliveryChecklistReducer, {
  putDeliveryChecklistReducer,
} from '../service/application/DeliveryChecklist';
import postSearchApplication, { searchKeyReducer } from '../service/application/SearchSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    registerProfessional: registerProfessionalReducer,
    verify: verifyReducer,
    verifyForgotPassword: verifyForgotPasswordReducer,
    forgotPassword: forgotPasswordReducer,
    newApplication: newApplicationReducer,
    supplier: supplierReducer,
    fileUpload: fileUpload,
    profile: profile,
    saveSupplier: saveSupplierReducer,
    saveBeneficiary: saveBeneficiaryReducer,
    applicationList: applicationListReducer,
    applicationDetails: getApplicationByIdReducer,
    allApplications: getAllApplicationsReducer,
    putStatus: putStatusReducer,
    putThread: putThreadReducer,
    getThread: getThreadReducer,
    getAttachments: getAttachmentReducer,
    postAttachment: postAttachmentThreadReducer,
    deleteAttachment: deleteAttachmentThreadReducer,
    batchApproval: batchApprovalReducer,
    getDeliveryChecklist: getDeliveryChecklistReducer,
    putDeliveryChecklist: putDeliveryChecklistReducer,
    searchApplication: postSearchApplication,
    searchKey: searchKeyReducer,
    updateProfile: updateProfileReducer,
    updatePassword: updatePasswordReducer,
    updatePicture: updatePictureReducer,
    profileImage: getPictureReducer,
    recentApplications: getRecentApplicationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
