import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import logo from '../../assets/images/mad_logo.png';
import { useStyles } from './RegisterProfessionalStyles';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  postRegisterProfessional,
  selectData,
  selectLoading,
} from '../../service/register/RegisterProfessionalSlice';
import { RegisterForm } from '../register/Register';
import { LoadingButton } from '@mui/lab';
import Toast from '../../component/Toast';
import { formatPhone, ValidateUrls } from '../../helper/AppHelper';
import createDecorator from 'final-form-focus';
import { colors } from '../../theme/Theme';

interface RegisterProfessionalForm {
  role: string | null | undefined;
  ahpra?: string | null | undefined;
  organisation: string | null | undefined;
  department: string | null | undefined;
  linkedIn?: string | null | undefined;
  instagram?: string | null | undefined;
}

const focusOnErrors: any = createDecorator();

const RegisterProfessional: FC<{}> = () => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(true);

  const loading = useAppSelector(selectLoading);
  const response = useAppSelector(selectData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onSubmit = async (values: RegisterProfessionalForm) => {
    const { email, password, title, firstName, lastName, phone, newUser } =
      location.state as RegisterForm;
    const { role, ahpra, organisation, department, linkedIn, instagram } = values;
    const data = {
      email,
      ...(!newUser && { password }),
      role: 'Health_Professional',
      phoneNumber: formatPhone(phone!),
      name: {
        firstName,
        lastName,
        title,
      },
      socials: {
        linkedIn,
        instagram,
      },
      workplaceInfo: {
        role,
        organisation,
        department,
        ahpra,
      },
      contactInfo: {
        phone: formatPhone(phone!),
        email,
      },
    };
    const response = await dispatch(postRegisterProfessional(data));
    if (response.type.includes('fulfilled')) {
      setOpen(true);
      if (newUser) {
        navigate('/login', { state: { newUser: true } });
      } else {
        navigate('/verify', { state: email });
      }
    }
  };

  const validate = (values: RegisterProfessionalForm) => {
    const { role, organisation, department, linkedIn, instagram } = values;
    const errors: RegisterProfessionalForm = {
      role: role
        ? undefined
        : 'Please enter your role, eg. Nurse, Carer, Doctor, Child support worker.',
      organisation: organisation
        ? undefined
        : 'Please enter your organisation, eg. Hospital name etc',
      department: department
        ? undefined
        : 'Please enter your Department, eg. Pediatrics, Orthopedics, Psychiatry, Family Counseling etc.',
      linkedIn: linkedIn
        ? ValidateUrls(linkedIn)
          ? undefined
          : 'Please enter a valid url'
        : undefined,
      instagram: instagram
        ? ValidateUrls(instagram)
          ? undefined
          : 'Please enter a valid url'
        : undefined,
    };
    return errors;
  };

  return (
    <Box
      height="100vh"
      display="flex"
      mb={8}
      justifyContent="center"
      flexDirection="column"
      borderLeft={`4px solid ${colors.pageBorder}`}
    >
      <img
        title="Go to Login"
        onClick={() => navigate('/login')}
        width={100}
        height={44}
        src={logo}
        style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
      />
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid item xs={11} sm={6} md={4} lg={3}>
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: { xs: '100%', sm: 420 },
              px: { xs: 1, sm: 5 },
              py: 4,
            }}
          >
            <Typography className={styles.register} variant="h3">
              Register
            </Typography>
            <Typography variant="subtitle2" className={styles.label}>
              Tell us about yourself.
            </Typography>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              decorators={[focusOnErrors]}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="role"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Role" />
                    )}
                  />
                  <Field
                    name="ahpra"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="AHPRA number (optional)" />
                    )}
                  />
                  <Field
                    name="organisation"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Organisation" />
                    )}
                  />
                  <Field
                    name="department"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Department" />
                    )}
                  />
                  <Typography variant="subtitle2" className={styles.social}>
                    Social
                  </Typography>
                  <Field
                    name="linkedIn"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="LinkedIn (optional)" />
                    )}
                  />
                  <Field
                    name="instagram"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Instagram (optional)" />
                    )}
                  />
                  <LoadingButton
                    loading={loading === 'loading'}
                    variant="contained"
                    type="submit"
                    className={styles.submitBtn}
                    fullWidth
                    color="primary"
                  >
                    Create Account
                  </LoadingButton>
                </form>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        successMsg={'Successfully registered!. Please verify your email to get started.'}
      />
    </Box>
  );
};

export default RegisterProfessional;
