import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/Theme';

export const useStyles = makeStyles({
  email: {
    marginTop: 6,
  },
  login: {
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'left',
  },
  submitBtn: {
    color: 'white',
  },
  label: {
    textAlign: 'left',
  },
  footer: {
    marginTop: 16,
  },
  loginBtn: {
    color: colors.primary,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 5,
  },
});
