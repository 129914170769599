import Logo from '../assets/images/mad_logo_bw.png';
import PractitionerLogo from '../assets/images/practitioner.png';
import SupplierLogo from '../assets/images/supplier.png';
import MadLogo from '../assets/images/mad_logo.png';
import Avatar from '../assets/images/avatar.png';
import GoogleIcon from '../assets/images/Google.png';

const PreloadImages = () => {
  // Add images to this list if you want them to be preloaded
  const images = [Logo, PractitionerLogo, SupplierLogo, MadLogo, Avatar, GoogleIcon];
  images.forEach((image) => {
    new Image().src = image;
  });
};

export default PreloadImages;
