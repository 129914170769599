import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../AxiosSetup';
import { ChecklistObj } from './ApplicationModels';

export interface DeliveryState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: DeliveryState = {
  status: 'idle',
  data: null,
};

export const getDeliveryChecklist = createAsyncThunk(
  'Get Delivery Checklist',
  async (applicationId: string, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.get(
        getUrl('checklist/get', { query: { applicationId } }),
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const putDeliveryChecklist = createAsyncThunk(
  'Put Delivery Checklist',
  async (data: ChecklistObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(getUrl('checklist/update', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const GetDeliveryChecklistSlice = createSlice({
  name: 'Get Delivery Checklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryChecklist.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDeliveryChecklist.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getDeliveryChecklist.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const PutDeliveryChecklistSlice = createSlice({
  name: 'Put Delivery checklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putDeliveryChecklist.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putDeliveryChecklist.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(putDeliveryChecklist.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectGetDeliveryChecklistLoading = (state: RootState) =>
  state.getDeliveryChecklist.status;
export const selectGetDeliveryChecklistData = (state: RootState) => state.getDeliveryChecklist.data;

export const selectPutDeliveryChecklistLoading = (state: RootState) =>
  state.putDeliveryChecklist.status;
export const selectPutDeliveryChecklistData = (state: RootState) => state.putDeliveryChecklist.data;

export default GetDeliveryChecklistSlice.reducer;

export const putDeliveryChecklistReducer = PutDeliveryChecklistSlice.reducer;
