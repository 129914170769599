import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../AxiosSetup';
import {
  ApplicationListPayload,
  GetApplicationPayload,
  GetAllApplicationsPayload,
} from './ApplicationModels';

export interface ApplicationListState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: ApplicationListState = {
  status: 'idle',
  data: null,
};

export const postApplicationList = createAsyncThunk(
  'applicationList',
  async (data: ApplicationListPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(
        getUrl('application/get/current-user', {}),
        data,
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const postGetAllApplications = createAsyncThunk(
  'getAllApplications',
  async (data: GetAllApplicationsPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(
        getUrl('application/get/all', {}),
        // getUrl('application/get/by-status', {}),
        data,
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getApplicationById = createAsyncThunk(
  'applicationList',
  async (data: GetApplicationPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.get(
        getUrl('application/get', { query: { applicationId: data.applicationId } }),
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getRecentApplications = createAsyncThunk(
  'recentApplications',
  async (data: {}, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('application/get/recent', {}), {}, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getApplicationListSlice = createSlice({
  name: 'applicationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postApplicationList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postApplicationList.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postApplicationList.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const getApplicationByIdSlice = createSlice({
  name: 'applicationDetails',
  initialState,
  reducers: {
    reset(state) {
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getApplicationById.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getApplicationById.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const getAllApplicationsSlice = createSlice({
  name: 'getAllApplications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postGetAllApplications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postGetAllApplications.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postGetAllApplications.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const getRecentApplicationsSlice = createSlice({
  name: 'recentApplications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecentApplications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRecentApplications.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getRecentApplications.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectGetApplicationListLoading = (state: RootState) => state.applicationList.status;
export const selectApplicationListData = (state: RootState) => state.applicationList.data;

export const selectGetAllApplicationsLoading = (state: RootState) => state.allApplications.status;
export const selectGetAllApplicationsData = (state: RootState) => state.allApplications.data;

export const selectGetApplicationByIdLoading = (state: RootState) =>
  state.applicationDetails.status;
export const selectGetApplicationByIdData = (state: RootState) => state.applicationDetails.data;

export const selectGetRecentApplicationsLoading = (state: RootState) =>
  state.recentApplications.status;

export default getApplicationListSlice.reducer;

export const getApplicationByIdReducer = getApplicationByIdSlice.reducer;
export const getAllApplicationsReducer = getAllApplicationsSlice.reducer;
export const getRecentApplicationsReducer = getRecentApplicationsSlice.reducer;
