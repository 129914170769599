import {
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
  IconButton,
  SelectChangeEvent,
  RadioGroup,
  Radio,
} from '@mui/material';
import React, { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './SupplierStyles';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSupplierLoading } from '../../service/supplier/SupplierSlice';
import Label from '../../component/Label';
import { postGetSupplierItems, postGetSuppliers } from '../../service/supplier/SupplierSlice';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { deleteFile, postFileUpload, selectLoading } from '../../service/upload/FileUploadSlice';
import { colors } from '../../theme/Theme';
import { GetKeyByValue, ValidateAmount, ValidateFiles } from '../../helper/AppHelper';
import createDecorator from 'final-form-focus';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import FileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { NamePathMapper } from '../application/Application';
import { postSupplier, selectSaveSupplierLoading } from '../../service/application/SupplierSlice';
import { Order, SupplierObj } from '../../service/application/ApplicationModels';
import { LoadingButton } from '@mui/lab';
import DocPreview from '../../component/DocPreview';
import { getLink } from '../../service/upload/ViewFileSlice';

export interface SupplierForm {
  supplier: string | null | undefined;
  supplierId?: string | null | undefined;
  item: string[] | null | undefined;
  itemId?: string[] | null | undefined;
  costType: 'Actual' | 'Estimated' | 'Estimate';
  cost?: string | null | undefined;
  highest?: string | null | undefined;
  lowest?: string | null | undefined;
  file: any | string | null | undefined;
  final: string | null | undefined;
  filePaths?: string[];
}

interface Form {
  suppliers: SupplierForm[];
}

interface Supplier {
  supplierId: string;
  companyName: string;
}

export interface SupplierItem {
  itemId: string;
  price: number;
  description: string;
  category: string;
  supplierId: string;
  displayName: string;
}

interface Props {
  supplierDetails: SupplierForm[];
  setSupplierDetails: Dispatch<any>;
  applicationId: string | undefined;
  setApplicationId: Dispatch<any>;
  supplierItems: SupplierItem[][];
  setSupplierItems: Dispatch<any>;
  fileNamePathMapper: NamePathMapper;
  setFileNamePathMapper: Dispatch<any>;
  fileIds: string[][];
  setFileIds: Dispatch<any>;
  setOpenSupplier: Dispatch<any>;
  modalOpen?: boolean;
  setModalType?: Dispatch<any>;
  setModalOpen?: Dispatch<any>;
  getData: () => void;
}

const focusOnErrors: any = createDecorator();

const Supplier: FC<Props> = ({
  supplierDetails,
  setSupplierDetails,
  supplierItems,
  setSupplierItems,
  fileNamePathMapper,
  setFileNamePathMapper,
  fileIds,
  setFileIds,
  applicationId,
  setApplicationId,
  setOpenSupplier,
  modalOpen,
  setModalOpen,
  setModalType,
  getData,
}) => {
  const styles = useStyles();
  const supplierLoading = useAppSelector(selectSupplierLoading);
  const [finalArr, setFinalArr] = useState<(string | null | undefined)[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[] | undefined>(undefined);
  const [fileErrors, setFileErrors] = useState<(string | boolean)[]>([]);
  const [error, setErrors] = useState<string | boolean>(false);
  const [update, setUpdate] = useState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fileLoading = useAppSelector(selectLoading);
  const lastSupplier = useRef(null);
  const profile = JSON.parse(localStorage.getItem('profile')!);
  const saveLoading = useAppSelector(selectSaveSupplierLoading);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [docLink, setDocLink] = useState<string | undefined>(undefined);
  const [docName, setDocName] = useState<string | undefined>(undefined);

  const { email: practitionerEmail } = profile || {};

  const setFinal = (value: string | undefined, index: number) => {
    const finalList = [...finalArr];
    finalList[index] = value;
    setFinalArr(finalList);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(postGetSuppliers({}));
      if (response.type.includes('fulfilled')) {
        setSuppliers(response.payload.results);
      }
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (selectedSupplier: string, index: number) => {
    const response = await dispatch(postGetSupplierItems({ supplierId: selectedSupplier }));
    if (response.type.includes('fulfilled')) {
      const supplierItemList: SupplierItem[][] = supplierItems || [];
      supplierItemList[index] = response.payload.results;
      setUpdate({ ...response.payload.results, update });
      setSupplierItems(supplierItemList);
    }
  };

  useEffect(() => {
    if (!supplierItems.length && supplierDetails.length) {
      supplierDetails.map((s: any, index: number) => {
        fetchData(s.supplierId, index);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierDetails]);

  const getSupplierName = (supplierId: string | null | undefined) => {
    if (supplierId) {
      return suppliers?.find((s) => s.supplierId === supplierId)?.companyName;
    }
    return 'N/A';
  };

  const getItemNames = (itemIds: string[] | null | undefined, index: number) => {
    return itemIds?.map((itemId: string) => {
      if (itemId) return supplierItems[index]?.find((s) => s.itemId === itemId)?.displayName;
      return 'N/A';
    });
  };

  const getInitialData = () => {
    if (supplierDetails && supplierDetails.length) {
      const final: (string | null | undefined)[] = [];
      const data = supplierDetails.map((s: SupplierForm, i: number) => {
        final[i] = s.final;
        return {
          ...s,
          supplier: s.supplierId,
          item: s.itemId,
        };
      });
      if (!finalArr.length) setFinalArr(final);
      return { suppliers: data };
    } else
      return {
        suppliers: [
          {
            supplier: undefined,
            cost: undefined,
            lowest: undefined,
            highest: undefined,
            costType: 'Actual',
            item: [undefined],
            file: undefined,
          },
        ],
      };
  };

  const getPostData = (values: Form) => {
    const orders: Order[] = values.suppliers.map((s: SupplierForm, i: number) => ({
      invoiceIsFinal: finalArr[i] === 'Yes',
      supplierId: s.supplier!,
      cost: {
        actualCost: parseInt(s.cost!, 10),
        highestCost: parseInt(s.highest!, 10),
        lowestCost: parseInt(s.lowest!, 10),
        costType: s.costType === 'Actual' ? 'Actual' : 'Estimate',
      },
      documents: fileIds[i].map((file: string) => ({
        id: file,
        name: GetKeyByValue(fileNamePathMapper, file),
      })),
      items: (s.item || []).map((itemId: string) => ({ itemId })),
    }));
    const data: SupplierObj = applicationId
      ? {
          applicationId,
          orders,
        }
      : {
          applicantEmail: practitionerEmail,
          orders,
        };
    return data;
  };

  const onSubmit = async (values: Form) => {
    const response = await dispatch(postSupplier(getPostData(values)));
    if (response.type.includes('fulfilled')) {
      const data = values.suppliers.map((s: SupplierForm, i: number) => ({
        ...s,
        final: finalArr[i],
        supplier: getSupplierName(s.supplier),
        supplierId: s.supplier,
        item: getItemNames(s.item, i),
        itemId: s.item,
        filePaths: fileIds[i],
      }));
      setOpenSupplier(true);
      setSupplierDetails(data);
      setApplicationId(response.payload.applicationId);
      if (modalOpen && setModalOpen && setModalType) {
        setModalOpen(false);
        setModalType(null);
        getData();
        return;
      }
      navigate('/new-application/beneficiary', {
        state: { applicationId: response.payload.applicationId },
      });
    }
  };

  const validateItems = (item: string[] | null | undefined) => {
    if (item)
      return item.map((i: string) => (!!i ? undefined : 'Please select item from the list'));
    return ['Please select item from the list'];
  };

  const validate = (values: Form) => {
    const errors: any = { suppliers: [] };
    (values.suppliers || []).forEach((value: SupplierForm) => {
      const { supplier, cost, item, file, final, costType, highest, lowest } = value;
      const fileValidator = ValidateFiles(file)[0] ? undefined : ValidateFiles(file)[1];
      const error: any = {
        supplier: supplier ? undefined : 'Please select a supplier from the list or enter one',
        cost:
          costType === 'Actual'
            ? ValidateAmount(cost)
              ? undefined
              : 'Please enter a valid cost'
            : undefined,
        lowest:
          costType === 'Estimated'
            ? ValidateAmount(lowest) && Number(lowest) < Number(highest)
              ? undefined
              : 'Please enter a valid lowest cost'
            : undefined,
        highest:
          costType === 'Estimated'
            ? ValidateAmount(highest) && Number(highest) > Number(lowest)
              ? undefined
              : 'Please enter a valid highest cost'
            : undefined,
        item: validateItems(item),
        file:
          fileValidator || fileValidator === undefined
            ? fileValidator
            : 'Please upload supplier quote',
        final: final ? undefined : 'Please select YES or NO',
      };
      // eslint-disable-next-line
      errors.suppliers.push(error);
    });
    return errors;
  };

  const handleRemove = async (index: number, setValue: Function, name: string) => {
    const temp = [...fileIds];
    const response = await dispatch(deleteFile({ path: temp[index][0] }));
    if (response.type.includes('fulfilled') || response.type.includes('rejected')) {
      temp[index] = [];
      setFileIds(temp);
      setValue(`${name}.file`, null);
    }
  };

  const handlePreview = async (name: string, path: string) => {
    setOpenPreview(true);
    const response = await dispatch(getLink({ path }));
    if (response.type.includes('fulfilled')) {
      setDocName(name);
      setDocLink(response.payload.message);
    }
  };

  const getFileNames = (
    files: FileList,
    error: boolean,
    index: number,
    setValue: Function,
    name: string,
  ) => {
    const box = [];
    for (let i = 0; i < files.length; i++) {
      box.push(
        <Box
          sx={{ marginTop: { xs: -2, sm: 0 }, marginBottom: { xs: 3, sm: 0 } }}
          key={`file-${files[i].name}`}
          height={18}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex">
            <FileOutlinedIcon color={error ? 'error' : 'primary'} />
            <Typography
              title="View"
              onClick={() => {
                handlePreview(files[i].name, fileNamePathMapper[files[i].name] || files[i].type);
              }}
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                fontSize: 16,
                textOverflow: 'ellipsis',
                maxWidth: 160,
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                height: 24,
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                  textDecorationColor: colors.primary,
                  color: colors.primary,
                },
                color: `${error ? colors.errorText : colors.grey700}`,
              }}
            >
              {files[i].name || 'Uploaded File'}
            </Typography>
          </Box>
          <Box pr={1}>
            {fileLoading === `loading-${files[i].name}` ||
            fileLoading === `deleting-${fileNamePathMapper[files[i].name]}` ? (
              <CircularProgress size="15px" />
            ) : (
              <IconButton
                sx={{ p: '0px 4px 3px 4px' }}
                onClick={() => handleRemove(index, setValue, name)}
              >
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            )}
          </Box>
        </Box>,
      );
    }
    return box;
  };

  const validateFile = (e: React.ChangeEvent<HTMLInputElement> | undefined, index: number) => {
    const validator = ValidateFiles(e!.target.files!);
    const errors = fileErrors;
    errors[index] = validator[0] ? false : validator[1] || 'Please upload supplier quote';
    setFileErrors(errors);
    setErrors(validator[1] || error);
    if (!validator[0]) {
      return false;
    }
    return true;
  };

  const handleFileUploaded = async (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    index: number,
    name: string,
    setValue: Function,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const files = e!.target!.files!;
    const fileIdList = [];
    for (let i = 0; i < files.length; i++) {
      const formData: any = new FormData();
      // eslint-disable-next-line
      formData.append('File', files[i]);
      // eslint-disable-next-line
      formData.append('Name', files[i].name);
      const response = await dispatch(postFileUpload(formData));
      if (response.type.includes('fulfilled')) {
        fileIdList.push(response.payload.path);
        const mapper: NamePathMapper = fileNamePathMapper;
        mapper[files[i].name] = response.payload.path;
        setFileNamePathMapper(mapper);
        if (i + 1 === files.length) {
          const idList = [...fileIds];
          idList[index] = fileIdList;
          setFileIds(idList);
        }
      } else if (response.type.includes('rejected')) {
        setValue(`${name}.file`, null);
        const errors = fileErrors;
        errors[index] = 'Upload failed, please try again';
        setFileErrors(errors);
        setErrors(errors[index]);
      }
    }
  };

  return (
    <Box pt={modalOpen ? 0 : 5}>
      <Grid style={{ display: 'flex', flexDirection: 'column' }} container>
        {!modalOpen && (
          <>
            <Typography color="primary" variant="h2">
              New application
            </Typography>
            <Typography style={{ maxWidth: 560 }} variant="h6" className={styles.label}>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo hendrerit
              mauris ut consectetur. */}
            </Typography>
          </>
        )}
        <Grid item xs={11} sm={10} md={10} lg={8} xl={8} style={{ maxWidth: 560 }}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            decorators={[focusOnErrors]}
            keepDirtyOnReinitialize
            initialValues={getInitialData()}
            mutators={{
              ...arrayMutators,
              setValue: (args, state, utils) => {
                utils.changeValue(state, args[0], () => args[1]);
              },
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, setValue, remove },
              },
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  style={{ background: colors.white }}
                  item
                  sx={{
                    padding: modalOpen ? '10px' : { xs: '24px 16px 32px 12px', sm: '30px 48px' },
                  }}
                >
                  <Typography className={styles.subText}>
                    {modalOpen ? 'Edit details' : 'Supplier and item/s'}
                  </Typography>
                  {/* <Typography variant="h6" className={styles.label}>
                    {modalOpen
                      ? ''
                      : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo hendrerit mauris ut consectetur.'}
                  </Typography> */}
                  <FieldArray name="suppliers">
                    {({ fields }) =>
                      fields.map((name: any, index: number) => (
                        <div key={`${name}-${index}`}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1">Supplier {index + 1}</Typography>
                            {index !== 0 && (
                              <IconButton
                                sx={{
                                  margin: '-2px 0px 4px 0px',
                                  borderRadius: 0,
                                  padding: '2px 10px',
                                }}
                                onClick={() => remove('suppliers', index)}
                              >
                                <Box display="flex">
                                  <DeleteOutlinedIcon fontSize="small" color="error" />
                                  <Typography
                                    variant="h6"
                                    sx={{ color: colors.errorText, marginLeft: '4px' }}
                                  >
                                    Remove
                                  </Typography>
                                </Box>
                              </IconButton>
                            )}
                          </Box>
                          <Field
                            name={`${name}.supplier`}
                            render={({ input, meta }) => (
                              <Box height={62}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    {...{ size: 'small' }}
                                    error={meta.touched && meta.error}
                                  >
                                    Select Supplier
                                  </InputLabel>
                                  <Select
                                    fullWidth
                                    disabled={supplierLoading === 'loading-suppliers'}
                                    size="small"
                                    endAdornment={
                                      <Box>
                                        {supplierLoading === 'loading-suppliers' && (
                                          <CircularProgress
                                            size="20px"
                                            style={{ marginRight: 20, marginTop: 5 }}
                                          />
                                        )}
                                      </Box>
                                    }
                                    inputProps={input}
                                    value={input.value}
                                    label="Select Supplier"
                                    onChange={(e) => {
                                      input.onChange(e);
                                      fetchData(e.target.value, index);
                                    }}
                                    error={meta.error && meta.touched}
                                  >
                                    {suppliers && suppliers.length ? (
                                      suppliers.map((s: Supplier) => (
                                        <MenuItem key={s.supplierId} value={s.supplierId}>
                                          {s.companyName}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem key={'NA'} value={undefined}>
                                        No suppliers available
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                                <Label
                                  style={{ paddingLeft: 14, paddingTop: 4 }}
                                  error={meta.touched && meta.error}
                                  text={meta.error}
                                />
                              </Box>
                            )}
                          />
                          <Field
                            name={`${name}.item`}
                            render={({ input, meta }) => (
                              <Box>
                                {Array((values as any).suppliers[index]!.item?.length || 1)
                                  .fill(0)
                                  .map((item, i) => (
                                    <Box mb={1}>
                                      <Box display="flex" justifyContent="space-between">
                                        <Typography variant="subtitle1">Item {i + 1}</Typography>
                                        {i !== 0 && (
                                          <IconButton
                                            sx={{
                                              mt: 1,
                                              borderRadius: 0,
                                              padding: '0px 10px',
                                              height: 25,
                                            }}
                                            onClick={() => {
                                              const items = [
                                                ...(values as any).suppliers[index]!.item,
                                              ];
                                              items.splice(i, 1);
                                              setValue(`${name}.item`, items);
                                            }}
                                          >
                                            <Box display="flex">
                                              <DeleteOutlinedIcon fontSize="small" color="error" />
                                              <Typography
                                                variant="h6"
                                                sx={{
                                                  color: colors.errorText,
                                                  marginLeft: '4px',
                                                }}
                                              >
                                                Remove
                                              </Typography>
                                            </Box>
                                          </IconButton>
                                        )}
                                      </Box>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          {...{ size: 'small' }}
                                          error={meta.touched && meta.error[i]}
                                        >
                                          Select Item
                                        </InputLabel>
                                        <Select
                                          fullWidth
                                          size="small"
                                          className={styles.focus}
                                          key={(values as any).suppliers[index]!.item[i]}
                                          disabled={supplierLoading === 'loading-items'}
                                          endAdornment={
                                            <Box>
                                              {supplierLoading === 'loading-items' && (
                                                <CircularProgress
                                                  size="20px"
                                                  style={{ marginRight: 20, marginTop: 5 }}
                                                />
                                              )}
                                            </Box>
                                          }
                                          value={(values as any).suppliers[index]!.item[i]}
                                          label="Select Item"
                                          onChange={(a: SelectChangeEvent) => {
                                            const curr = [...input.value];
                                            curr[i] = a.target.value;
                                            setValue(`${name}.item`, curr);
                                          }}
                                          error={meta.touched && meta.error[i]}
                                        >
                                          {supplierItems &&
                                          supplierItems[index] &&
                                          supplierItems[index].length ? (
                                            supplierItems[index].map((s: SupplierItem) => (
                                              <MenuItem key={s.itemId} value={s.itemId}>
                                                {s.displayName}
                                              </MenuItem>
                                            ))
                                          ) : (
                                            <MenuItem key={'NA'} value={undefined}>
                                              No items available
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      <Label
                                        style={{ paddingLeft: 14, paddingTop: 4 }}
                                        error={meta.touched && meta.error[i]}
                                        text={meta.touched ? meta.error[i] : ''}
                                      />
                                    </Box>
                                  ))}
                              </Box>
                            )}
                          />
                          <Button
                            onClick={() =>
                              setValue(`${name}.item`, [
                                ...(values as any).suppliers[index]!.item,
                                undefined,
                              ])
                            }
                            className={styles.addItemBtn}
                            variant="text"
                            startIcon={<AddIcon />}
                          >
                            Add Item
                          </Button>
                          <Box>
                            <RadioGroup
                              onChange={(e) => {
                                setValue(`${name}.costType`, e.target.value);
                                if (e.target.value === 'Actual') {
                                  setValue(`${name}.lowest`, undefined);
                                  setValue(`${name}.highest`, undefined);
                                } else {
                                  setValue(`${name}.cost`, undefined);
                                }
                              }}
                              value={(values as any).suppliers[index]!.costType}
                              sx={{ mb: 1 }}
                              row
                            >
                              <FormControlLabel
                                value="Actual"
                                control={<Radio />}
                                label="Actual cost"
                              />
                              <FormControlLabel
                                value="Estimated"
                                control={<Radio />}
                                label="Estimated cost"
                              />
                            </RadioGroup>
                            {(values as any).suppliers[index]!.costType === 'Actual' ? (
                              <Box sx={{ width: '48%' }}>
                                <Field
                                  name={`${name}.cost`}
                                  render={({ input, meta }) => (
                                    <CustomInput
                                      type="number"
                                      placeholder="00.00"
                                      startAdornment="amount"
                                      input={input}
                                      meta={meta}
                                      label="Cost"
                                    />
                                  )}
                                />
                              </Box>
                            ) : (
                              <Box display="flex">
                                <Box sx={{ width: { xs: '100%', sm: '48%' }, mr: 2 }}>
                                  <Field
                                    name={`${name}.lowest`}
                                    render={({ input, meta }) => (
                                      <CustomInput
                                        type="number"
                                        placeholder="00.00"
                                        startAdornment="amount"
                                        input={input}
                                        meta={meta}
                                        label="Lowest amount"
                                      />
                                    )}
                                  />
                                </Box>
                                <Box sx={{ width: { xs: '100%', sm: '48%' } }}>
                                  <Field
                                    name={`${name}.highest`}
                                    render={({ input, meta }) => (
                                      <CustomInput
                                        type="number"
                                        placeholder="00.00"
                                        startAdornment="amount"
                                        input={input}
                                        meta={meta}
                                        label="Highest amount"
                                      />
                                    )}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{ display: { xs: 'block', sm: 'flex' } }}
                            justifyContent="space-between"
                          >
                            <Box sx={{ width: { xs: '100%', sm: '48%' } }}>
                              <Field
                                type="checkbox"
                                name={`${name}.final`}
                                render={({ input, meta }) => (
                                  <Box>
                                    <Label
                                      error={meta.touched && meta.error}
                                      alwaysShow
                                      size={16}
                                      text="Is the supplier quote final?"
                                    />
                                    <Box display="flex" ml="2px">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={finalArr[index] === 'Yes'}
                                            onChange={(e) => {
                                              input.onChange(e);
                                              setFinal(e.target.checked ? 'Yes' : undefined, index);
                                            }}
                                          />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={finalArr[index] === 'No'}
                                            onChange={(e) => {
                                              input.onChange(e);
                                              setFinal(e.target.checked ? 'No' : undefined, index);
                                            }}
                                          />
                                        }
                                        label="No"
                                      />
                                    </Box>
                                    {meta.touched && meta.error && (
                                      <Label
                                        error={meta.touched && meta.error}
                                        style={{ marginTop: -6, marginBottom: 10, paddingLeft: 14 }}
                                        text={meta.touched && meta.error}
                                      />
                                    )}
                                  </Box>
                                )}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: { xs: '100%', sm: '48%' },
                                marginTop: { xs: 1, sm: 0 },
                                pt: 1.5,
                              }}
                            >
                              <Field
                                name={`${name}.file`}
                                type="file"
                                render={({ input, meta }) => (
                                  <Box mb={3}>
                                    {input.value && input.value.length ? (
                                      <Box mt={1}>
                                        {getFileNames(
                                          input.value,
                                          (meta.touched && meta.error) || fileErrors[index],
                                          index,
                                          setValue,
                                          name,
                                        )}
                                      </Box>
                                    ) : (
                                      <div
                                        className={
                                          (meta.touched && meta.error) || fileErrors[index]
                                            ? styles.dropBoxError
                                            : styles.dropBox
                                        }
                                      >
                                        <label
                                          style={{ width: '100%', cursor: 'pointer' }}
                                          htmlFor={`contained-button-file-${name}`}
                                        >
                                          <input
                                            onChange={(e) => {
                                              const valid = validateFile(e, index);
                                              if (!valid) return;
                                              handleFileUploaded(e, index, name, setValue);
                                              input.onChange(e.target.files);
                                            }}
                                            style={{ display: 'none' }}
                                            id={`contained-button-file-${name}`}
                                            type="file"
                                          />
                                          <Box
                                            width="100%"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            px={'10px'}
                                            pt="2px"
                                          >
                                            <Typography
                                              variant="h5"
                                              sx={{
                                                textOverflow: 'ellipsis',
                                                maxWidth: 180,
                                                whiteSpace: 'nowrap',
                                                overflowX: 'hidden',
                                              }}
                                              color={
                                                (meta.touched && meta.error) || fileErrors[index]
                                                  ? colors.errorText
                                                  : 'action'
                                              }
                                            >
                                              Upload Supplier Quote
                                            </Typography>
                                            <FileUploadIcon
                                              color={
                                                (meta.touched && meta.error) || fileErrors[index]
                                                  ? 'error'
                                                  : 'action'
                                              }
                                            />
                                          </Box>
                                        </label>
                                      </div>
                                    )}
                                    {((meta.touched && meta.error) || fileErrors[index]) && (
                                      <Label
                                        sx={{
                                          pl: '14px',
                                          pt: {
                                            xs: 0,
                                            sm: input.value.length ? 2 : '2px',
                                          },
                                        }}
                                        error={(meta.touched && meta.error) || fileErrors[index]}
                                        text={fileErrors[index] || meta.error}
                                      />
                                    )}
                                  </Box>
                                )}
                              />
                            </Box>
                          </Box>
                          {fields && ((fields || []).length || 0) - 1 !== index && (
                            <Divider style={{ margin: '30px 0px', borderColor: 'black' }} />
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="contained"
                    disableElevation
                    className={styles.submitBtn}
                    fullWidth
                    color="secondary"
                    endIcon={<AddIcon />}
                    onClick={() => {
                      push('suppliers', {
                        supplier: undefined,
                        cost: undefined,
                        item: [undefined],
                        file: undefined,
                        lowest: undefined,
                        highest: undefined,
                        costType: 'Actual',
                      });
                      setTimeout(() => {
                        // eslint-disable-next-line
                        (lastSupplier as any).current.scrollIntoView({ behavior: 'smooth' });
                      }, 0);
                    }}
                  >
                    {'Add Another Supplier'}
                  </Button>
                </Grid>
                <LoadingButton
                  variant="contained"
                  sx={{ width: { xs: '100%', sm: 180 }, mb: modalOpen ? '5px' : '0px' }}
                  type="submit"
                  loading={saveLoading === 'loading'}
                  className={styles.nextBtn}
                  disabled={fileLoading.includes('loading')}
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                >
                  {modalOpen ? 'Save' : 'Next'}
                </LoadingButton>
              </form>
            )}
          />
        </Grid>
      </Grid>
      <DocPreview
        open={openPreview}
        setOpen={setOpenPreview}
        link={docLink}
        name={docName || 'Uploaded File'}
        setDocLink={setDocLink}
        setDocName={setDocName}
      />
      <div ref={lastSupplier} />
    </Box>
  );
};

export default Supplier;
