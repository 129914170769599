import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getUrl } from '../../service/AxiosSetup';

export interface VerifyState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: VerifyState = {
  status: 'idle',
  data: null,
};

export interface VerifyPayload {
  confirmationCode: string | null | undefined;
  email: string | null | undefined;
}

export const postVerify = createAsyncThunk(
  'verify',
  async (data: VerifyPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('auth/confirm-signup', {}), data);
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const verifySlice = createSlice({
  name: 'verify',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postVerify.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postVerify.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(postVerify.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.verify.status;
export const selectData = (state: RootState) => state.verify.data;

export default verifySlice.reducer;
