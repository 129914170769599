export interface Document {
  id: string;
  name: string;
  type?: string;
}

interface Item {
  itemId: string;
}

export interface Order {
  supplierId: string;
  cost: Cost;
  invoiceIsFinal: boolean;
  documents: Document[];
  items: Item[];
}

export interface SupplierObj {
  applicantEmail?: string;
  applicationId?: string;
  orders: Order[];
}

//Beneficiary part 2 API models

interface BeneficiaryDetails {
  parentEmail: string;
  dateOfBirth: string;
  documents: Document[];
  englishSpeaking: boolean;
  languageSpoken: string | null;
  beneficiaryName: BeneficiaryName;
  parentName: ParentName;
  phoneNo: string;
}

interface BeneficiaryName {
  preferredName: string;
  firstName: string;
  lastName: string;
}

interface ParentName {
  firstName: string;
  lastName: string;
}

export interface BeneficiaryObj {
  applicationId: string;
  beneficiaryDetails: BeneficiaryDetails;
}

export interface ApplicationPayload {
  applicationId: string;
  applicationStatus: string;
}

export interface ApplicationListPayload {
  paginationOption?: {
    size: number;
    paginationToken: any;
    pageBackwards: boolean;
  };
}

export interface GetAllApplicationsPayload {
  status?: string;
  paginationOption?: {
    size: number;
    paginationToken?: any;
    pageBackwards?: boolean;
  };
}

export interface GetApplicationPayload {
  applicationId: string;
}

export interface BeneficiaryDetail {
  preferredName: string;
  title: string;
  firstName: string;
  lastName: string;
  shortName: string;
  fullName: string;
}

export interface ParentDetails {
  preferredName: string;
  title: string;
  firstName: string;
  lastName: string;
  shortName: string;
  fullName: string;
}

export interface BeneficiaryInfo {
  parentEmail: string;
  dateOfBirth: string;
  documents: Document[];
  englishSpeaking: boolean;
  beneficiaryName: BeneficiaryDetail;
  parentName: ParentDetails;
  phoneNo: string;
}

export interface ItemDetails {
  itemId: string;
  description: string;
}

export interface Cost {
  actualCost: number;
  costType: 'Actual' | 'Estimate';
  highestCost: number;
  lowestCost: number;
}

export interface OrderDetails {
  orderId: string;
  companyName: string;
  supplierId: string;
  cost: Cost;
  invoiceIsFinal: boolean;
  documents: Document[];
  items: ItemDetails[];
}

export interface ApplicationResponse {
  applicationId: string;
  dateLodged: Date;
  dateLastUpdated: Date;
  applicationStatus: string;
  practitionerId: string;
  beneficiaryInfo: BeneficiaryInfo;
  orders: OrderDetails[];
}

export enum Status {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
  RequestNotSent = 'Request not sent',
  RequestSent = 'Request sent',
  Off = 'Off',
  Draft = 'Draft',
  InProgress = 'In progress',
  AwaitingApproval = 'Awaiting approval',
  Complete = 'Complete',
  WithSupplier = 'With supplier',
  QuoteNotFinal = 'Quote not final',
  Delivery = 'Delivery',
}

export interface StatusObj {
  applicationId: string;
  approvalStatus: Status;
  message?: string;
  email?: string;
}

export interface ThreadObj {
  applicationId: string;
  message: string;
}

export interface ThreadMsg {
  applicationId: string;
  authorName: string;
  dateTimePosted: string;
  message: string;
}

export interface ThreadAttachmentRequest {
  applicationId: string;
  document: {
    id: string;
    name: string;
  };
}

export interface ThreadAttachmentDeleteRequest {
  applicationId: string;
  documentId: string;
}

export interface ThreadAttachment {
  applicationId: string;
  dateTimePosted: string;
  document: {
    id: string;
    name: string;
  };
}

export interface Approval {
  applicationId: string;
  approvalStatus: string;
  dateCreated: string;
  dateLastUpdated: string;
  email: string;
  isAdminApproval: boolean;
  message: string;
  name: string;
}

export interface ChecklistObj {
  applicationId: string;
  checklistId: string;
  itemId: string;
  isComplete: boolean;
}
