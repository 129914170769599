import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  active: {
    color: 'white',
    fontWeight: 600,
    fontSize: 13,
    padding: '0px 10px',
    '&:hover': {
      background: '#007DFF',
      border: '1px solid #007DFF',
    },
    '&:disabled': {
      color: 'white',
    },
  },
  inactive: {
    background: '#A8B0B9',
    color: 'white',
    border: '1px solid #a8b0b9',
    fontWeight: 600,
    fontSize: 13,
    padding: '0px 10px',
    '&:hover': {
      background: '#007DFF',
      border: '1px solid #007DFF',
    },
    '&:disabled': {
      color: 'white',
    },
  },
});
