import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import logo from '../../assets/images/mad_logo.png';
import GoogleIcon from '../../assets/images/Google.png';
import Label from '../../component/Label';
import { useStyles } from './LoginStyles';
import { colors } from '../../theme/Theme';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import CustomInput from '../../component/CustomInput';
import { setTokens, ValidateEmail } from '../../helper/AppHelper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getGoogleSigninUrl,
  postGoogleCode,
  postLogin,
  selectData,
  selectLoading,
} from '../../service/login/LoginSlice';
import { LoadingButton } from '@mui/lab';
import Toast from '../../component/Toast';
import Loader from '../../component/FullScreenLoader';

interface LoginForm {
  email: string | null | undefined;
  password: string | null | undefined;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  expiry: string;
  userId: string;
}

const Login: FC<{}> = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [openSuccessTab, setOpenSuccessTab] = useState<boolean>(false);
  const [signinUrl, setSiginiUrl] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const loading = useAppSelector(selectLoading);
  const response = useAppSelector(selectData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getGoogleSigninUrl());
      if (response.type.includes('fulfilled')) {
        setSiginiUrl(response.payload.url);
      }
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state && (location.state as { newUser: boolean }).newUser) {
      const { newUser } = location.state as { newUser: boolean };
      setOpenSuccessTab(newUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    const search = location.search;
    const codes = search ? search.split('code=') : [];
    const code = codes && codes.length === 2 ? codes[1] : false;
    if (code) {
      const fetchData = async () => {
        setLoader(true);
        const response = await dispatch(postGoogleCode({ code }));
        if (response.type.includes('fulfilled')) {
          setTokens(response.payload);
          if (location.state && (location.state as any).from) {
            navigate((location.state as any).from);
            return;
          }
          navigate('/');
        } else {
          setLoader(false);
        }
      };
      fetchData().catch(console.error);
    } else {
      const url = search ? decodeURIComponent(search) : '';
      if (url && search.includes('#Existing Native#')) {
        return;
      }
      if (url && search.includes('error')) {
        const email = url.substring(url.indexOf('#email#') + 7, url.lastIndexOf('#email#'));
        const firstName = url.substring(
          url.indexOf('#given_name#') + 12,
          url.lastIndexOf('#given_name#'),
        );
        const lastName = url.substring(
          url.indexOf('#family_name#') + 13,
          url.lastIndexOf('#family_name#'),
        );
        navigate('/register', { state: { newUser: true, email, firstName, lastName } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const styles = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    // eslint-disable-next-line
    event.preventDefault();
  };

  const onSubmit = async (form: LoginForm) => {
    const { email, password } = form;
    setOpen(true);
    setLoader(true);
    const response = await dispatch(postLogin({ email, password }));
    if (response.type.includes('fulfilled')) {
      setTokens(response.payload);
      if (location.state && (location.state as any).from) {
        navigate((location.state as any).from);
        return;
      }
      navigate('/');
    } else {
      setLoader(false);
    }
  };

  const validate = (values: LoginForm) => {
    const errors: LoginForm = {
      email: ValidateEmail(values.email) ? undefined : 'Please enter a valid email address',
      password: values.password ? undefined : 'Please enter password',
    };
    return errors;
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessTab(false);
  };

  return (
    <Box
      borderLeft={`4px solid ${colors.pageBorder}`}
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <img width={100} height={44} src={logo} style={{ position: 'absolute', top: 10, left: 15 }} />
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid
          item
          xs={11}
          sm={6}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: 420,
              px: { xs: 1, sm: 5 },
              py: 4,
            }}
          >
            <Typography className={styles.login} variant="h3">
              Log in
            </Typography>
            {/* <Typography className={styles.login} variant="h6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo hendrerit
              mauris ut consectetur.
            </Typography> */}
            <Box>
              <Button
                startIcon={
                  <img style={{ margin: '0 5px' }} src={GoogleIcon} width={20} height={20} />
                }
                fullWidth
                size="small"
                variant="outlined"
                className={styles.googleBtn}
                disabled={!signinUrl}
                onClick={() => {
                  setLoader(true);
                  window.open(signinUrl!, '_self');
                }}
              >
                {loading === 'loading' || loader ? 'Signing in' : 'Sign in with google'}
              </Button>
            </Box>
            <Box display="flex" mb={1}>
              <Box my={1.5} color={colors.grey600} fontWeight={700} display="flex" mx="auto">
                or
              </Box>
            </Box>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    render={({ input, meta }) => (
                      <CustomInput input={input} meta={meta} label="Email address" />
                    )}
                  />
                  <Field
                    name="password"
                    render={({ input, meta }) => (
                      <Box height={50}>
                        <TextField
                          {...input}
                          error={meta.touched && meta.error}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword ? 'text' : 'password'}
                          size="small"
                          label="Password"
                          fullWidth
                        />
                        <Label
                          style={{ paddingLeft: 14, paddingTop: 4 }}
                          error={meta.touched && meta.error}
                          text={meta.error}
                        />
                      </Box>
                    )}
                  />
                  <Button
                    onClick={() => navigate('/forgot-password')}
                    className={styles.forgotPasswordBtn}
                    color="inherit"
                    variant="text"
                  >
                    Forgot password?
                  </Button>
                  <LoadingButton
                    loading={loading === 'loading' || loader}
                    variant="contained"
                    type="submit"
                    className={styles.submitBtn}
                    fullWidth
                    color="primary"
                  >
                    Sign in
                  </LoadingButton>
                </form>
              )}
            />
            <Box mt={3} display="flex" justifyContent="center" alignItems="baseline">
              <Typography variant="h6">New User?</Typography>
              <Button
                onClick={() => navigate('/register')}
                className={styles.createBtn}
                color="inherit"
                variant="text"
              >
                Create Account
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Loader override={loader} />
      <Toast loading={loading} response={response} open={open} setOpen={setOpen} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSuccessTab}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={loading === 'failed' ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          Registration successful, please login to get started
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
