import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl } from '../../service/AxiosSetup';
import { StatusObj } from './ApplicationModels';

export interface StatusState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: StatusState = {
  status: 'idle',
  data: null,
};

export const putStatus = createAsyncThunk(
  'Status',
  async (data: StatusObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(getUrl('approval/update', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const putBatchApproval = createAsyncThunk(
  'Approval Reqest',
  async (applicationId: string, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(
        getUrl('approval/update/batch', {}),
        { applicationId },
        getConfig(true),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const StatusSlice = createSlice({
  name: 'Status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putStatus.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(putStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const BatchApprovalSlice = createSlice({
  name: 'Status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putBatchApproval.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putBatchApproval.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(putBatchApproval.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectStatusLoading = (state: RootState) => state.putStatus.status;
export const selectStatusData = (state: RootState) => state.putStatus.data;

export const selectBatchApprovalLoading = (state: RootState) => state.putStatus.status;
export const selectBatchApprovalStatusData = (state: RootState) => state.putStatus.data;

export default StatusSlice.reducer;

export const batchApprovalReducer = BatchApprovalSlice.reducer;
