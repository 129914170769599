import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../theme/Theme';
import { useStyles } from './FilterStyle';

interface Props {
  filters: string[];
  activeFilter: string[];
  handleFilter: Function;
  activeFilterColor?: string;
}

const Filter: FC<Props> = ({ filters, activeFilter, handleFilter, activeFilterColor }) => {
  const styles = useStyles();
  return (
    <Box display="flex" pb={1.5}>
      {filters.map((filter: string) => (
        <Button
          onClick={() => handleFilter(filter)}
          sx={{
            mr: 1,
            height: 30,
            bgcolor: activeFilterColor || colors.activeFilter,
            border: `1px solid ${activeFilterColor ? activeFilterColor : colors.activeFilter}`,
          }}
          variant="outlined"
          disabled={filters.length < 2}
          className={activeFilter.includes(filter) ? styles.active : styles.inactive}
        >
          {filter.toUpperCase()}
        </Button>
      ))}
    </Box>
  );
};

export default Filter;
