import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { FC, useState } from 'react';
import logo from '../../assets/images/mad_logo.png';
import { useStyles } from './VerificationStyles';
import { Form, Field } from 'react-final-form';
import Label from '../../component/Label';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { colors } from '../../theme/Theme';
import { CensorEmail, ValidatePassword } from '../../helper/AppHelper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectData, selectLoading, postVerify } from '../../service/forgotPassword/VerifySlice';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Toast from '../../component/Toast';

interface VerificationForm {
  code: string | null | undefined;
  password: string | null | undefined;
  confirmPassword: string | null | undefined;
}

const Verification: FC<{}> = () => {
  const styles = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const loading = useAppSelector(selectLoading);
  const response = useAppSelector(selectData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values: VerificationForm) => {
    const { code, password } = values;
    setOpen(true);
    const response = await dispatch(
      postVerify({
        confirmationCode: code,
        email: location.state as string,
        newPassword: password,
      }),
    );
    if (response.type.includes('fulfilled')) {
      setError('');
      setTimeout(function () {
        navigate('/login');
      }, 2000);
    } else if (response.type.includes('rejected')) {
      if (response.payload.message.toLowerCase().includes('invalid')) {
        setError(response.payload.message);
      }
    }
  };
  const validate = (values: VerificationForm) => {
    const { code, password, confirmPassword } = values;
    const errors: VerificationForm = {
      code: code && code.length === 6 ? undefined : 'Enter a valid code',
      password: ValidatePassword(password),
      confirmPassword:
        confirmPassword && password === confirmPassword ? undefined : 'Passwords don’t match',
    };
    return errors;
  };

  const censoredEmail = CensorEmail(location.state as string);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    // eslint-disable-next-line
    event.preventDefault();
  };
  return (
    <Box
      height="100vh"
      borderLeft={`4px solid ${colors.pageBorder}`}
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <img
        title="Go to Login"
        onClick={() => navigate('/login')}
        width={100}
        height={44}
        src={logo}
        style={{ position: 'absolute', top: 10, left: 15, cursor: 'pointer' }}
      />
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container>
        <Grid item xs={11} sm={7} md={5} lg={4}>
          <Box
            sx={{
              bgcolor: { sm: 'white' },
              border: { sm: `1px solid ${colors.sectionBorder}` },
              borderRadius: { sm: 2 },
              width: '95%',
              px: { xs: 2, sm: 4 },
              py: 4,
            }}
          >
            <Typography className={styles.login} variant="h3">
              Almost there!
            </Typography>
            <Typography variant="h6" className={styles.label}>
              We’ve sent you a verification code to {censoredEmail}. Please check your inbox and
              enter the code below to get started.
            </Typography>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="code"
                    render={({ input, meta }) => (
                      <Box mt={1} mb={2} display="flex" justifyContent="center">
                        <Box width={300}>
                          <Label
                            style={{ marginLeft: 3 }}
                            error={meta.touched && meta.error}
                            text="Enter your 6 digit code"
                          />
                          <Box display="flex" justifyContent="center">
                            <OtpInput
                              value={input.value}
                              onChange={input.onChange}
                              hasErrored={(meta.touched && meta.error) || !!error}
                              numInputs={6}
                              errorStyle={{
                                border: `1px solid ${colors.errorText}`,
                                background: colors.errorBg,
                              }}
                              isInputNum
                              inputStyle={{
                                width: 40,
                                height: 40,
                                margin: 6,
                                borderRadius: 4,
                                border: `1px solid ${colors.grey600}`,
                                fontSize: 14,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  />
                  <Typography variant="h6" className={styles.labelCenter}>
                    Enter your new password below
                  </Typography>
                  <br />
                  <Field
                    name="password"
                    render={({ input, meta }) => (
                      <Box height={72} margin="auto">
                        <TextField
                          {...input}
                          label="New password"
                          error={meta.touched && meta.error}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword ? 'text' : 'password'}
                          size="small"
                          fullWidth
                        />
                        <Label
                          style={{ paddingLeft: 14, paddingTop: 4 }}
                          error={meta.touched && meta.error}
                          text={meta.error}
                        />
                      </Box>
                    )}
                  />
                  <Box
                    textAlign="left"
                    color={colors.activeFilter}
                    fontSize={12}
                    display="flex"
                    mt={-2.5}
                    height={80}
                  >
                    <ul>
                      <li>One lowercase character</li>
                      <li>One uppercase character</li>
                      <li>One number</li>
                    </ul>
                    <ul>
                      <li>One special character</li>
                      <li>8 charactres minimum</li>
                    </ul>
                  </Box>
                  <Field
                    name="confirmPassword"
                    render={({ input, meta }) => (
                      <Box height={72} margin="auto">
                        <TextField
                          {...input}
                          label="Confirm new password"
                          error={meta.touched && meta.error}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showConfirmPassword ? 'text' : 'password'}
                          size="small"
                          fullWidth
                        />
                        <Label
                          style={{ paddingLeft: 14, paddingTop: 4 }}
                          error={meta.touched && meta.error}
                          text={meta.error}
                        />
                      </Box>
                    )}
                  />
                  <LoadingButton
                    loading={loading === 'loading'}
                    variant="contained"
                    fullWidth
                    type="submit"
                    className={styles.submitBtn}
                    color="primary"
                    disabled={!!errors?.code}
                  >
                    Change password
                  </LoadingButton>
                  <Box mt={1} display="flex" justifyContent="center" alignItems="baseline">
                    <Typography variant="h6">Have an account?</Typography>
                    <Button
                      onClick={() => navigate('/')}
                      className={styles.loginBtn}
                      color="inherit"
                      variant="text"
                    >
                      Sign in instead
                    </Button>
                  </Box>
                </form>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Toast
        loading={loading}
        response={response}
        open={open}
        setOpen={setOpen}
        successMsg="Password successfully changed, please login"
      />
    </Box>
  );
};

export default Verification;
