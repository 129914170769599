import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl, HttpContentType } from '../../service/AxiosSetup';

export interface State {
  status: string;
  data: any;
}

const initialState: State = {
  status: 'idle',
  data: null,
};

export interface FileViewPayload {
  path: string;
  duration?: string;
}

export const getLink = createAsyncThunk(
  'getLink',
  async (data: FileViewPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    const { path, duration = 60 } = data;
    try {
      const response = await axios.get(
        getUrl('/upload/share', { query: { path, duration } }),
        getConfig(true, HttpContentType.MultipartFormData),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const fileViewSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLink.pending, (state, action) => {
      const x: any = action.meta.arg;
      // eslint-disable-next-line
      for (const pair of x.entries()) {
        if (pair[0] === 'Name') {
          state.status = `loading-${pair[1]}`;
        }
      }
    });
  },
});

export const selectViewFileLoading = (state: RootState) => state.fileUpload.status;
export const selectViewFileData = (state: RootState) => state.fileUpload.data;

export default fileViewSlice.reducer;
