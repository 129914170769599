import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './app/store';
import App from './App';
import './index.css';
import Login from './container/login/Login';
import ForgotPassword from './container/forgotPassword/ForgotPassword';
import Register from './container/register/Register';
import RegisterProfessional from './container/registerProfessional/RegisterProfessional';
import RegisterSupplier from './container/registerSupplier/RegisterSupplier';
import Verification from './container/verification/Verification';
import ForgotPasswordVerification from './container/forgotPassword/Verification';
import PrivateRoute from './container/layout/PrivavteRoute';
import Home from './container/home/Home';
import Application from './container/application/Application';
import { ScrollToTop } from './app/ScrollToTop';
import ApplicationTracking from './container/applicationTracking/ApplicationTracking';
import ApplicationList from './container/applicationTracking/ApplicationList';
import Settings from './container/settings/Settings';
import 'typeface-roboto';
import ErrorPage from './container/layout/ErrorPage';
import Help from './container/help/Help';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/login/*" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register-healthcare-professional" element={<RegisterProfessional />} />
            <Route path="/register-supplier" element={<RegisterSupplier />} />
            <Route path="/verify" element={<Verification />} />
            <Route path="/verify-forgot-password" element={<ForgotPasswordVerification />} />

            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="new-application/*" element={<Application />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="my-applications" element={<ApplicationTracking />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="application-tracking" element={<ApplicationList />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="account-settings" element={<Settings />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="help" element={<Help />} />
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
