import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  register: {
    marginBottom: 8,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  submitBtn: {
    color: 'white',
  },
  label: {
    marginBottom: 12,
  },
  select: {
    height: 40,
  },
  social: {
    marginBottom: 12,
  },
});
