import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import getAxiosInstance, { getConfig, getUrl, HttpContentType } from '../AxiosSetup';
import { FileViewPayload } from '../upload/ViewFileSlice';
import { PasswordObj, PictureObj, ProfileObj } from './ProfileModels';

export interface State {
  status: 'idle' | 'loading' | 'failed' | 'success';
  data: any;
}

const initialState: State = {
  status: 'idle',
  data: null,
};

export const getProfile = createAsyncThunk('get-profile', async (__, { rejectWithValue }) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios.get(getUrl('auth/profile', {}), getConfig(true));
    return response.data;
  } catch (e: any) {
    if (!e.response) {
      throw e;
    }
    return rejectWithValue(e.response.data);
  }
});

export const updateProfile = createAsyncThunk(
  'update-profile',
  async (data: ProfileObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.patch(getUrl('user/update', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const updatePicture = createAsyncThunk(
  'update-picture',
  async (data: PictureObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.put(getUrl('user/profile-picture', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const updatePassword = createAsyncThunk(
  'update-password',
  async (data: PasswordObj, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    try {
      const response = await axios.post(getUrl('auth/change-password', {}), data, getConfig(true));
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getProfileImageLink = createAsyncThunk(
  'get-profile-picture',
  async (data: FileViewPayload, { rejectWithValue }) => {
    const axios = getAxiosInstance();
    const { path, duration = 60 } = data;
    try {
      const response = await axios.get(
        getUrl('/upload/share', { query: { path, duration } }),
        getConfig(true, HttpContentType.MultipartFormData),
      );
      return response.data;
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const getProfileSlice = createSlice({
  name: 'get-profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const updateProfileSlice = createSlice({
  name: 'update-profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const updatePasswordSlice = createSlice({
  name: 'update-password',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const updatePictureSlice = createSlice({
  name: 'update-picture',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePicture.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePicture.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(updatePicture.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const getProfilePictureSlice = createSlice({
  name: 'get-profile-picture',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileImageLink.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfileImageLink.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getProfileImageLink.rejected, (state, action) => {
        state.status = 'failed';
        state.data = action.payload;
      });
  },
});

export const selectLoading = (state: RootState) => state.profile.status;
export const selectData = (state: RootState) => state.profile.data;

export const selectUpdateProfileLoading = (state: RootState) => state.updateProfile.status;
export const selectUpdateProfileData = (state: RootState) => state.updateProfile.data;

export const selectUpdatePasswordLoading = (state: RootState) => state.updatePassword.status;
export const selectUpdatePasswordData = (state: RootState) => state.updatePassword.data;

export const selectUpdatePictureLoading = (state: RootState) => state.updatePicture.status;
export const selectUpdatePictureData = (state: RootState) => state.updatePicture.data;

export const selectGetProfilePictureLoading = (state: RootState) => state.profileImage.status;
export const selectGetProfilePictureData = (state: RootState) => state.profileImage.data;

export const updateProfileReducer = updateProfileSlice.reducer;
export const updatePasswordReducer = updatePasswordSlice.reducer;
export const updatePictureReducer = updatePictureSlice.reducer;
export const getPictureReducer = getProfilePictureSlice.reducer;

export default getProfileSlice.reducer;
