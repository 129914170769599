import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/Theme';

export const useStyles = makeStyles({
  dropBox: {
    width: '100%',
    display: 'flex',
    height: 40,
    flexDirection: 'column',
    fontSize: 14,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 5,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
  },
  dropBoxError: {
    width: '100%',
    display: 'flex',
    marginBottom: 15,
    height: 40,
    flexDirection: 'column',
    fontSize: 14,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: `1px solid ${colors.errorText}`,
    borderRadius: 5,
  },
});
