import * as axios from 'axios';
import { forceLogout, setTokens } from '../helper/AppHelper';
import AxiosInstance from './AxiosInstance';
import QueryPath from './QueryPath.data';

export enum HttpContentType {
  Json = 'application/json',
  MultipartFormData = 'multipart/form-data',
}

export const getUrl = (serviceType: string, path?: QueryPath): string => {
  let url = `${serviceType}`;

  if (path) {
    if (path.route && path.route.length > 0) {
      for (const route of path.route) {
        if (route) {
          url += `/${route}`;
        }
      }
    }

    if (path.query) {
      let separator = '?';

      for (const name in path.query) {
        if (!Array.isArray(path.query[name]) && (path.query[name] || path.query[name] === false)) {
          url += `${separator}${encodeURI(name)}=${encodeURI(path.query[name]!.toString())}`;
          separator = '&';
        } else if (Array.isArray(path.query[name])) {
          for (const value of path.query[name]! as string[]) {
            url += `${separator}${encodeURI(name)}=${encodeURI(value)}`;
            separator = '&';
          }
        }
      }
    }
  }

  return url;
};

export const getConfig = (authenticated: boolean, contentType?: HttpContentType): any => {
  const type = contentType ? contentType.toString() : 'application/json';
  const headers = authenticated
    ? {
        'Content-Type': type,
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      }
    : {
        'Content-Type': type,
      };
  return {
    headers,
  };
};

const getAxiosInstance = (): axios.AxiosInstance => {
  const instance = AxiosInstance;
  const currentRefreshToken = localStorage.getItem('refreshToken');
  const userId = localStorage.getItem('userId');
  instance.interceptors.response.use(
    (response): any => {
      return response;
    },
    (error): any => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        if (error.config.url.includes('signin') || error.config.url.includes('refresh-token')) {
          return Promise.reject(error);
        }
        instance
          .post('auth/refresh-token', {
            refreshToken: currentRefreshToken,
            userId: userId,
          })
          .then((response) => {
            const { accessToken } = response.data;
            setTokens(response.data);
            error.config.headers.Authorization = `Bearer ${accessToken}`;
            return instance(error.config);
          })
          .catch((err) => {
            console.log('Could not refresh access token', err);
            forceLogout();
            return null;
          });
      }
    },
  );
  return instance;
};
export default getAxiosInstance;
