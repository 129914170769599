import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/Theme';

export const useStyles = makeStyles({
  subText: {
    marginBottom: 6,
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 600,
  },
  submitBtn: {
    marginTop: 20,
  },
  nextBtn: {
    marginTop: 20,
    float: 'right',
  },
  label: {
    marginBottom: 20,
  },
  dropBox: {
    width: '100%',
    display: 'flex',
    height: 40,
    flexDirection: 'column',
    fontSize: 14,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
  },
  dropBoxError: {
    width: '100%',
    display: 'flex',
    height: 40,
    flexDirection: 'column',
    fontSize: 14,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: `1px solid ${colors.errorText}`,
    borderRadius: 5,
  },
  addItemBtn: {
    color: colors.primaryHeader,
    display: 'flex',
    padding: 5,
    marginTop: 0,
    marginBottom: 6,
    fontWeight: 400,
    height: 25,
  },
  focus: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
    },
  },
});
